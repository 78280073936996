import React from 'react';
import { useAppSelector } from '../../store/configureStore';
import { Box, Button, Grid, IconButton, Modal, TextField, Typography } from '@material-ui/core';
// Components
import CarDetails from './CarDetails';
import JobDetails from './JobDetails';
import JobsMultipleSelection from './JobsMultipleSelection';
import ContactForm from './ContactForm';
import moment from 'moment';
import { getHour, getStatusData } from '../../utils/appointments';
import { AppointmentType, ChangeAppointmentStatusPayload } from '../../types/Agenda';
import { changeAppointmentStatus } from '../agenda/agenda.requests';
import { ContactFormData } from '../agenda/agenda.types';
import CloseIcon from '@material-ui/icons/Close';
import { TranslatorService } from '../../services/TranslatorService';
import AppointmentClientSelectionDetails from './AppointmentClientSelectionDetails';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

const AppointmentDetails = ({appointment, setIsLoading, getSelectedAppointment}:{appointment?:AppointmentType , setIsLoading:any, getSelectedAppointment: any}) => {
  const [date, setDate] = React.useState<Date | null >(null);
  const [hour, setHour] = React.useState<string | null>(null);
  const [contactFormData, setContactFormData] = React.useState<ContactFormData | null>(null);
  const [comment, setComment] = React.useState<string>('');
  const statusList = useAppSelector((state)=>state.agenda?.statusList)
  const language = useAppSelector((state) => state.app?.language)
  const [openCommentModal, setOpenCommentModal] = React.useState<boolean>(false);
  const translatorService = new TranslatorService();

  const renderLastStatus = () =>{
    const { text, color } = getStatusData(appointment?.appointmentStatus?.code || '', statusList, language || 'ro');
    return <Box style={{display:'flex', flexDirection:'column', alignContent:'center', justifyContent:'space-between', height:'90%'}}>
      <div>
      <Box
        style={{
          padding:'10px 32px',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          backgroundColor: color,
          marginBottom:16
        }}
      >
        <Typography variant="h6" style={{color:'white'}}>
          {text}
        </Typography>
      </Box>
      </div>
      {appointment?.appointmentStatus?.code !== statusList?.CANCELED?.code && !appointment?.hideCancelButton ? <Button
        variant="contained"
        style={{ backgroundColor: 'rgb(248, 50, 69)', color: 'white' }}
        onClick={(e) => setOpenCommentModal(true)}
      >
        {translatorService.Tranlate('APPOINTMENT_CLIENT_SELECTION_CANCEL', 'Cancel')}
      </Button>:<></>}
    </Box>
  }

  const changeStatus = async (status: number, comment?:string) => {
    setIsLoading(true)
    if (!appointment) {
      setIsLoading(false)
      return;
    }

    const payload: ChangeAppointmentStatusPayload = {
      appointmentId: appointment?.id,
      statusId: status,
      comment:comment
    }

    if (status === statusList.RESCHEDULED.id) {
      const finalDate = date;
      const splitHour = hour != null ? hour.split(':') : null;
      if (finalDate && splitHour) {
        finalDate.setHours(parseInt(splitHour[0]));
        finalDate.setMinutes(parseInt(splitHour[1]));
        payload.date = moment(finalDate).format();
      }
    }

    const res = await changeAppointmentStatus(payload)

    if (res.error) {
      setIsLoading(false)
      return;
    }


    getSelectedAppointment(true);
    setIsLoading(false);
  }

  React.useEffect(() => {
    if(appointment?.date){
      setDate(new Date(appointment.date))
      setHour(getHour(new Date(appointment.date)))
      setContactFormData({
        firstName: appointment?.appointmentClient?.firstName || '',
        lastName: appointment?.appointmentClient?.lastName || '',
        phoneNo: appointment?.appointmentClient?.phone || '',
        email: appointment?.appointmentClient?.email || '',
        username: appointment?.appUsername || ''
      })
    }
  }, []);

  React.useEffect(() => {
    if(appointment?.date){
      setDate(new Date(appointment.date));
      setHour(getHour(new Date(appointment.date)));
    }
  }, [appointment?.date]);

  const renderCancelForm =  () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
         changeStatus(statusList.CANCELED.id, comment);
          setOpenCommentModal(false)
        }}
      > 
        <div style={{ paddingRight: 30, paddingLeft: 30 }}>
          <Typography style={{ marginBottom: 16 }}>
            {translatorService.Tranlate('APPOINTMENT_ADD_SERVICE_COMMENT_INFO', 'This message will be displayed to the user')}
          </Typography>
          <TextValidator
            fullWidth
            variant="outlined"
            multiline={true}
            style={{ marginTop: 0, marginBottom: 40 }}                   
            name="comment"
            placeholder={translatorService.Tranlate('APPOINTMENT_COMMENT', "Comment")}
            value={comment || ''}
            onChange={(e: any) => setComment(e.target.value)}
            validators={['required']}
            errorMessages={[
              translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
            ]}
            label={translatorService.Tranlate('APPOINTMENT_COMMENT', "Comment")}
          />
        </div>
        <div className="text-center">
          <Button
            variant="contained"
            type="submit"
            style={{ backgroundColor: '#5383ff', color: 'white', alignSelf: 'center', marginBottom: 30 }}
          >
            {translatorService.Tranlate('APPOINTMENT_CLIENT_SEND', 'Send')}
          </Button>
        </div>
      </ValidatorForm>
    );
  }

  const addComment = () => {
    return (
      <div>
        <Modal open={openCommentModal} onClose={() => { setOpenCommentModal(false); }}>
          <div className="search-vehicle-modal" style={{ width: 350, display: 'flex', justifyContent: 'center', flexDirection: 'column', }}>
            <div className="text-right">
              <IconButton onClick={() => { setOpenCommentModal(false); }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography variant="h4" style={{ marginBottom: 40, textAlign: 'center' }}>
              {translatorService.Tranlate('APPOINTMENT_ADD_SERVICE_COMMENT_TITLE', 'Add service comment')}
            </Typography>
            {renderCancelForm()}
          </div>
        </Modal>
      </div>
    );
  };

  return (<div className="app-inner-content-layout ">
    <div className="w-100 bg-white p-0">
      <div style={{ padding: 32 }}>
        <Box style={{ display: 'flex', }}>
          <div className='appointmentDetailsContainer'>
            <div className='appointmentDetailsField'>
              <Box style={{ width:'100%'}}>
                <Typography variant="h5" style={{ textAlign: 'left', marginBottom: '10px', marginTop: 16 }}>
                  {translatorService.Tranlate('APPOINTMENT_CAR_TITLE', 'Car')}
                </Typography>
                {appointment?.vehicle && <CarDetails selectedCar={appointment?.vehicle} />}
              </Box>
            </div>
            {contactFormData ? <div className='appointmentDetailsField'>
              <ContactForm formData={contactFormData} type='details'/>
              </div> : null}
            <div className='appointmentDetailsField' style={{ display: 'flex', flexWrap: 'wrap', marginTop: 16 }}>
              <div style={{ flex: 1 }}>
                <JobDetails 
                  inputProps={{
                    style: { height: '100%', alignItems: 'baseline', color: 'black' },
                    classes: {
                      notchedOutline: 'custom-outline'
                    }
                  }}
                  type={'details'} 
                  disabled 
                  details={appointment?.jobDetails} />
              </div>
            </div>
            <div className='appointmentDetailsField'>
              <Box >
              <Typography variant="h5" style={{ marginBottom: '10px', marginTop: 16, color: 'black', textAlign: 'center' }}>
                {translatorService.Tranlate('APPOINTMENT_LAST_STATUS', 'Last status:')}
              </Typography>
              {renderLastStatus()}
              </Box>
            </div>
          </div>
          
        </Box>

        <Box style={{ display: 'flex', flexWrap: 'wrap', marginTop:30 }}>
          <JobsMultipleSelection
            selectedValues={appointment?.appointmentJobTypes || []}
            disabled
          />
        </Box>



        <Box className='appointmentClientSelectionDetails' style={{ marginBottom: 10,}}>
          <Typography variant="h5" style={{ marginBottom: '16px', marginTop: 26, }}>
            {translatorService.Tranlate('APPOINTMENT_CLIENT_DATE_SELECTIONS', 'Client Selections + Reschedule')}
          </Typography>
          <AppointmentClientSelectionDetails 
            appointmentId={appointment?.id || null}
            plateNumber={appointment?.vehicle?.plateNumber || null}
            clientDateSelections={appointment?.appointmentClientSelections || []}
            status={appointment?.appointmentStatus || null}
            changeStatus={changeStatus}
            date={date}
            setDate={setDate}
            hour={hour}
            setHour={setHour}
            getSelectedAppointment={
              () => {
                setIsLoading(true);
                getSelectedAppointment(true);
              }
            }
            caseId={appointment?.caseId || null}
            appointmentCategoryType={appointment?.appointmentCategoryType || null}
            setIsLoading={setIsLoading}
          />
        </Box>
        {addComment()}
      </div>
    </div>
  </div>
  );
};

export default AppointmentDetails;
