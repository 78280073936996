import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  CardContent,
  Button,
  IconButton,
  DialogTitle,
  Grid,
  Typography,
  ButtonGroup,
  Tooltip,
  Box,
  Dialog,
  DialogContent,
  Modal,
  DialogActions,
  DialogContentText,
  Divider
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { Card, CardFooter } from 'reactstrap';
import { ScaleLoader } from 'react-spinners';
import { WorkflowDocumentType, WorkflowFormAction, WorkflowSectionStep, WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { isNullOrUndefined } from 'util';
import { Appointment, CarfixAttachmentNotificationRequest, CarfixAttachmentNotificationResponse, CaseAttachment, CaseSelectedAttachment } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import Referentials from '../../helpers/Referentials.json';
import { AttachmentTypeCode, CaseStatusCodeEnum, ReferentialCode, ReportTypeCode, UploadSizeLimit, WorkflowFormActionTypeCode } from '../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import FileSaver from 'file-saver';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import { AppUser } from '../../interfaces/AppUser';
import CloseIcon from '@material-ui/icons/Close';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileViewer from 'react-file-viewer';
import { IReferential } from '../../interfaces/IReferential';
import GetAppIcon from '@material-ui/icons/GetApp';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import _, { at } from 'lodash';
import { Organization } from '../../interfaces/Organization';
import * as R from 'ramda';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { workers } from 'cluster';
import Resizer from "react-image-file-resizer";
import { ReportParameter } from '../../interfaces/Reports';
import { IReportsService } from '../../services/Interfaces/IReportsService';

interface ICaseDetailsCaseTabDocumentsFormState {
  caseAttachments: CaseAttachment[];
  attachmentTypeList: WorkflowDocumentType[];
  isLoading: boolean;
  hasRights: boolean;
  executing: boolean;
  isDialogOpen: boolean;
  previewAttachFile: any;
  previewAttachFileExtension: string;
  isCameraDialogOpen: boolean;
  isSelection: boolean;
  attachmentTypeClicked: any;
  showUploadMessage: boolean;
  isOpen: boolean;
  dialogVisible: boolean;
  selectedAttachment: CaseSelectedAttachment;
  caseStatuses: IReferential[];
  wfTransitionStepAction: WorkflowFormAction | null;
  appointment: Appointment | null;
}

export interface ExternalCaseDetailsCaseTabDocumentsFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseTabDocumentsFormProps = ExternalCaseDetailsCaseTabDocumentsFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;
export const imageExtensions = ['png', 'jpeg', 'jpg', 'bmp'];

class CaseDetailsCaseTabDocumentsForm extends React.PureComponent<
  ICaseDetailsCaseTabDocumentsFormProps,
  ICaseDetailsCaseTabDocumentsFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;
  private organizationService!: IOrganizationService;
  private reportsService!: IReportsService;
  static contextType = ApplicationContext;
  state = {
    caseAttachments: [],
    attachmentTypeList: [],
    isLoading: false,
    hasRights: false,
    executing: false,
    isDialogOpen: false,
    previewAttachFile: '',
    previewAttachFileExtension: '',
    isCameraDialogOpen: false,
    isSelection: false,
    attachmentTypeClicked: null,
    showUploadMessage: false,
    isOpen: false,
    dialogVisible: false,
    selectedAttachment: {
      id: -1,
      fileName: ''
    },
    caseStatuses: [],
    wfTransitionStepAction: null,
    appointment: null
  } as ICaseDetailsCaseTabDocumentsFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseDocumentsForm();
      }
    );
  }

  loadCaseDocumentsForm = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.AttachmentType
    );
    const refCaseStatus = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CaseStatus
    );

    let workflowDocumentsTypes = this.props.workflowForm.workflowDocumentTypes;
    const [attachmentTypes, caseStatuses, appointment] = await Promise.all([
      this.appReferentialService.Get(ref!.baseUrl),
      this.appReferentialService.Get(refCaseStatus!.baseUrl),
      this.props.workflowForm.appointmentCategoryTypeId != null ? this.caseService.GetAppointmentByType(caseId, this.props.workflowForm.appointmentCategoryTypeId) : null,
    ]);

    workflowDocumentsTypes.forEach((wfDoc) => {
      const documentType = attachmentTypes.find((item) => item.id === wfDoc.documentTypeId);
      wfDoc.documentType = isNullOrUndefined(documentType) ? null : documentType;
    });

    let organization: Organization | null = null;
    if (!R.isNil(this.props.appState!.appUser!.organizationId)) {
      organization = await this.organizationService.GetOrganizationInfo(
        this.props.appState!.appUser!.organizationId
      );
    }
    
    let attachmentTypeList = _.cloneDeep(this.props.workflowForm.workflowDocumentTypes);
    console.log('isPrcIntegration', this.props.caseSettingsState.caseSettings!.workflow.isPrcIntegration);
    console.log('hideOtherAttachments', this.props.appState.appUser!.organization!.organizationType!.hideOtherAttachments);

    if (this.props.caseSettingsState.caseSettings!.workflow.isPrcIntegration && this.props.appState.appUser!.organization!.organizationType!.hideOtherAttachments) {
      workflowDocumentsTypes = workflowDocumentsTypes.filter((item) => item.documentType!.code != AttachmentTypeCode.OTHER);
    }

    let caseAttachments = appointment ? 
      await this.caseService.GetCaseAppointmentAttachments(
        caseId,
        appointment.id,
        workflowDocumentsTypes.map((item) => item.documentType!.code!)
      )
      : 
      await this.caseService.GetCaseAttachments(
        caseId,
        workflowDocumentsTypes.map((item) => item.documentType!.code!)
      )
    ;

    const step = this.getWorkflowSectionStep(this.props.workflowForm.workflowSectionStepId);
    console.log('step', step);
    console.log('current', step && step.isCurrent(this.props.caseSettingsState.case!.caseStatus.caseStatusId));
    if (
      this.props.caseSettingsState.caseSettings!.workflow.isCarfixIntegration &&
      step && 
      step.isCurrent(this.props.caseSettingsState.case!.caseStatus.caseStatusId)
    ) {
      console.log('attachmentTypeList', attachmentTypeList);
    
      const relevantAttachmentTypes = [
        AttachmentTypeCode.WORK_ORDER.toString(),
        AttachmentTypeCode.HAND_OVER.toString(),
        AttachmentTypeCode.INVOICE_REPAIR.toString()
      ];
    
      const hasRelevantAttachment = attachmentTypeList.some(attach =>
        relevantAttachmentTypes.includes(attach.documentType!.code || '')
      );
    
      if (hasRelevantAttachment) {
        const hasRelevantCaseAttachment = caseAttachments.some(attach =>
          relevantAttachmentTypes.includes(attach.attachmentType!.code || '')
        );
    
        if (hasRelevantCaseAttachment) {
          console.log('The caseAttachments contains a relevant attachment.');
        } else {
          console.log('The caseAttachments does not contain any relevant attachments.');

          const workflowDocumentType = attachmentTypeList.find(attach =>
            relevantAttachmentTypes.includes(attach.documentType!.code || '')
          );
          console.log('workflowDocumentType', workflowDocumentType);
    
          if (workflowDocumentType) {
            await this.generateReport(workflowDocumentType, appointment);
            caseAttachments = appointment ? 
              await this.caseService.GetCaseAppointmentAttachments(
                caseId,
                appointment.id,
                workflowDocumentsTypes.map((item) => item.documentType!.code!)
              )
              : 
              await this.caseService.GetCaseAttachments(
                caseId,
                workflowDocumentsTypes.map((item) => item.documentType!.code!)
              )
            ;
          }
        }
      } else {
        console.log('The attachmentTypeList does not contain any relevant attachments.');
      }
    }    

    const photosProm = [] as any[];
    caseAttachments.forEach((element) => {
      const categ = workflowDocumentsTypes.find(
        (wdt) => wdt.documentTypeId === element.attachmentTypeId
      );
      if (!categ!.isMultiple && categ!.isSelectionGroup) {
        photosProm.push(this.caseService.GetCaseAttachment(element.fileName));
      } else {
        photosProm.push(null);
      }
    });

    const photos = await Promise.all([...photosProm]);
    caseAttachments.forEach((elem, index) => {
      elem.file = photos[index];
    });

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    if (this.props.caseSettingsState.caseSettings!.workflow.isPrcIntegration && this.props.appState.appUser!.organization!.organizationType!.hideOtherAttachments) {
      attachmentTypeList = attachmentTypeList.filter((item) => item.documentType!.code != AttachmentTypeCode.OTHER);
    }
    attachmentTypeList.forEach((attachmentType) => {
      attachmentType.isLoading = false;
    });

    const wfStepActions = this.props.workflowForm.workflowFormActions;
    const wfTransitionStepAction =
      wfStepActions.find(
        (item) =>
          item.actionType?.code === WorkflowFormActionTypeCode.STATUS_TRANSITION &&
          this.props.caseSettingsState.case!.caseStatus.caseStatusId === item.fromStatusId
      ) || (null);

    console.log('appointment', appointment);
    this.setState({
      caseAttachments: caseAttachments.sort(function (a: any, b: any) {
        return b.uploadDate - a.uploadDate;
      }),
      attachmentTypeList: attachmentTypeList,
      isLoading: false,
      hasRights: hasRights,
      showUploadMessage: organization!.uploadFeature,
      caseStatuses: caseStatuses,
      wfTransitionStepAction: wfTransitionStepAction,
      appointment: appointment
    });
  };

  getWorkflowSectionStep = (workflowSectionStepId: number): WorkflowSectionStep | null => {
    const sections = this.props.caseSettingsState.caseSettings!.workflow.workflowSections!;
    for (const section of sections) {
      for (const step of section.workflowSectionSteps) {
        if (step.id == workflowSectionStepId) {
          return step;
        }
      }
    }
    return null;
  };

  setCameraDetails = async (attachmentType: WorkflowDocumentType) => {
    this.setState({
      attachmentTypeClicked: attachmentType.documentType!,
      isCameraDialogOpen: true,
      isSelection: attachmentType.isSelectionGroup
    });
  };

  setAttachmentTypeList = (workflowDocumentType: WorkflowDocumentType, value: boolean) => {
    const attachmentTypeList = _.cloneDeep(this.state.attachmentTypeList);
    for (let i = 0; i < attachmentTypeList.length; i++) {
      if (attachmentTypeList[i].id === workflowDocumentType.id) {
        attachmentTypeList[i].isLoading = value;
      }
    }

    return attachmentTypeList;
  };

  /**
   * Get dimensions of any image by url
   * @param {string} url
   * @param {number} [rejectTimeout] timeout for reject
   * @return {Promise<{ width: number, height: number }>}
   */
  reactImageSize = (
    url: string,
    rejectTimeout: number
  ): Promise<{ width: number; height: number }> =>
    new Promise((resolve, reject) => {
      let timer: NodeJS.Timeout | null = null;

      const img = new Image();

      img.addEventListener('load', () => {
        if (timer) {
          clearTimeout(timer);
        }

        resolve({ width: img.width, height: img.height });
      });

      img.addEventListener('error', (event) => {
        if (timer) {
          clearTimeout(timer);
        }

        reject(`${event.type}: ${event.message}`);
      });

      img.src = url;

      if (rejectTimeout) {
        timer = setTimeout(() => reject('Timeout exception'), rejectTimeout);
      }
    });

  resizeImageFile = (file: any) : Promise<File> =>
    new Promise((resolve, reject) => {
      try {
        //The Pixels value For 5 Megabytes at 4:3 Respect Ratio and 24 Bit depth is:
        //1526 X 1145
        Resizer.imageFileResizer(
          file,
          1526,
          1145,
          "jpg",
          100,
          0,
          (uri) => {
            resolve(uri as File);
          },
          "file",
          600,
          800
        );
      } catch (err) {
				console.log(err);
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
			}
    
  });

  handleUploadAttachment = async (
    e: React.ChangeEvent<HTMLInputElement>,
    workflowDocumentType: WorkflowDocumentType
  ) => {
    try {
      if (e.target.files === null) {
        return;
      }
      e.persist();
      if (this.state.showUploadMessage) {
        if (e.target.files.length > 80 && workflowDocumentType.isMultiple) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate('MORE_PHOTOS', 'Limita de 80 de poze a fost depasita!'),
            {
              variant: 'warning'
            }
          );
          e.target.value = '';
          return;
        }
      }
      this.setState({
        attachmentTypeList: this.setAttachmentTypeList(workflowDocumentType, true)
      });
      const unacceptedFileList: string[] = [];
      const attachments: CaseAttachment[] = [];
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files![i];
        
        //pentru selection grup
        if (workflowDocumentType.isSelectionGroup) {
            const ext = file.name.toLowerCase().split('.').pop();
            if (ext && imageExtensions.includes(ext)) {
              console.log('Before', file);
              file = await this.resizeImageFile(e.target.files![i]);
              console.log('After', file);
            } else {
              unacceptedFileList.push(file.name);
            }
        }

        attachments.push({
          id: 0,
          caseId: this.props.caseSettingsState.case!.id,
          attachmentTypeId: workflowDocumentType.documentTypeId,
          attachmentType: workflowDocumentType.documentType,
          file: file,
          isSelection: workflowDocumentType.isSelectionGroup
        } as CaseAttachment);
      }

      if (workflowDocumentType.isSelectionGroup) {
        if (unacceptedFileList.length) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate('RENAULT_ACCEPTED_FORMAT', 'Tipul fisierului acceptat este png, jpeg, jpg, bmp!') + ' \n' +
            this.translatorService.Tranlate('RENAULT_ACCEPTED_FORMAT_MESSAGE', 'Urmatoarele fisiere nu indeplinesc acesta conditie: ') + ' \n' +
              unacceptedFileList.join(', '),
            {
              variant: 'warning',
              style: { whiteSpace: 'pre-line' }
            }
          );
          e.target.value = '';

          this.setState({
            attachmentTypeList: this.setAttachmentTypeList(workflowDocumentType, false)
          });
          return;
        }
      }

      const size = _.sumBy(attachments, 'file.size');
      console.log('Dimensiune bytes', size);

      if (this.state.showUploadMessage) {
        if (size > UploadSizeLimit.SizeLimit) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate('MAX_SIZE_PHOTOS', 'Limita de 500 MB a fost depasita!'),
            {
              variant: 'warning'
            }
          );
          e.target.value = '';

          this.setState({
            attachmentTypeList: this.setAttachmentTypeList(workflowDocumentType, false)
          });
          return;
        }
      }

      this.setState({
        attachmentTypeList: this.setAttachmentTypeList(workflowDocumentType, true)
      });

      const newFiles = await this.caseService.AddCaseAttachment(attachments);

      newFiles.forEach((item, index) => {
        item.file = attachments[index].file;
      });

      const caseAttachments = [...this.state.caseAttachments];
      caseAttachments.push(...newFiles);

      this.setState({
        caseAttachments: caseAttachments,
        attachmentTypeList: this.setAttachmentTypeList(workflowDocumentType, false)
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
      e.target.value = '';
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
      e.target.value = '';
    }
  };

  handleUploadAttachmentFromCamera = async (files: any, attachmentType: IReferential) => {
    try {
      if (files === null) {
        return;
      }
      if (this.state.showUploadMessage) {
        if (files.length > 80) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate('MORE_PHOTOS', 'Limita de 80 de poze a fost depasita!'),
            {
              variant: 'warning'
            }
          );
          return;
        }
      }

      const attachments: CaseAttachment[] = [];
      for (let i = 0; i < files.length; i++) {
        attachments.push({
          id: 0,
          caseId: this.props.caseSettingsState.case!.id,
          attachmentTypeId: attachmentType.id,
          attachmentType: attachmentType,
          file: files![i],
          isSelection: this.state.isSelection
        } as CaseAttachment);
      }

      if (this.state.showUploadMessage) {
        const size = _.sumBy(attachments, 'file.size');
        if (size > UploadSizeLimit.SizeLimit) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate('MAX_SIZE_PHOTOS', 'Limita de 500 MB a fost depasita!'),
            {
              variant: 'warning'
            }
          );
          return;
        }
      }

      const newFiles = await this.caseService.AddCaseAttachment(attachments);
      newFiles.forEach((item, index) => {
        item.file = attachments[index].file;
      });

      const caseAttachments = [...this.state.caseAttachments];
      caseAttachments.push(...newFiles);

      this.setState({
        caseAttachments: caseAttachments
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleCloseConfirmationDialog = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  handleOpenConfirmationDialog = () => {
    this.setState({
      isOpen: true,
      dialogVisible: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  openDeleteAttachmentPopUp = (id: number, fileName: string, fileInputKey?: number) => {
    this.setState({
      isOpen: true,
      dialogVisible: true,
      selectedAttachment: {
        id: id,
        fileName: fileName,
        fileInputKey: fileInputKey
      }
    });
  };

  handleDeleteAttachment = async (id: number, fileName: string, fileInputKey?: number) => {
    try {
      this.setState({ isOpen: false, dialogVisible: false });
      await this.caseService.RemoveCaseAttachment(fileName);

      const caseAttachments = [...this.state.caseAttachments];
      const index = caseAttachments.findIndex((item) => item.id === id);
      caseAttachments.splice(index, 1);

      this.setState({
        caseAttachments: caseAttachments,
        attachmentTypeList: this.state.attachmentTypeList.map((item) => {
          if (item.fileInputKey === fileInputKey) {
            return { ...item, fileInputKey: Date.now() };
          }
          return item;
        })
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handlePreviewAttachment = async (originalFileName: string, filename: string) => {
    try {
      const fileBlob = await this.caseService.GetCaseAttachment(filename);
      const url = URL.createObjectURL(fileBlob);
      const extension = originalFileName.split('.').pop();

      if (isNullOrUndefined(extension)) {
        return;
      }

      this.setState({
        previewAttachFile: url,
        previewAttachFileExtension: extension!.toLowerCase(),
        isDialogOpen: true
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  renderPreviewAttachment = () => {
    return (
      <FileViewer
        fileType={this.state.previewAttachFileExtension}
        filePath={this.state.previewAttachFile}
      />
    );
  };

  renderPreviewImage = (file: any, fileName: string) => {
    const url = URL.createObjectURL(file);

    return <img src={url} alt={fileName} className="img-fluid" />;
  };

  renderAttachments = (workflowDocument: WorkflowDocumentType) => {
    const attachments = this.state.caseAttachments.filter(
      (item) => item.attachmentTypeId === workflowDocument.documentTypeId
    );
    const attachmentsToRender = [] as any[];

    attachments.forEach((attach, index) => {
      attachmentsToRender.push(
        <React.Fragment key={index}>
          <Grid container spacing={0}>
            <Grid item xs={1}>
              <div className="d-flex align-items-center">
                <IconButton
                  aria-label="preview"
                  color="inherit"
                  className="text-primary"
                  size="small"
                  onClick={(e) =>
                    this.handlePreviewAttachment(attach.originalFileName, attach.fileName)
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={9}>
              <div className="align-box-row text-wrap " style={{ wordBreak: 'break-all' }}>
                <small>
                  <Button
                    color="primary"
                    className="m-2 text-primary"
                    onClick={(e) => this.downloadFile(attach.fileName, attach.originalFileName)}
                  >
                    <span>{attach.originalFileName}</span>
                  </Button>{' '}
                </small>
              </div>
            </Grid>

            <Grid item xs={2}>
              <div className="d-flex align-items-center">
                <IconButton
                  aria-label="download"
                  color="inherit"
                  size="small"
                  onClick={(e) => this.downloadFile(attach.fileName, attach.originalFileName)}
                >
                  <GetAppIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="inherit"
                  hidden={!this.state.hasRights}
                  className="text-danger"
                  size="small"
                  onClick={(e) =>
                    this.openDeleteAttachmentPopUp(
                      attach.id,
                      attach.fileName,
                      workflowDocument.fileInputKey
                    )
                  }
                >
                  <DeleteOutlineTwoToneIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={12}>
              {workflowDocument.isMultiple === false && workflowDocument.isSelectionGroup ? (
                <div style={{ flexBasis: '100%' }}>
                  <div className="d-flex align-items-center w-100 h-100">
                    {this.renderPreviewImage(attach.file, attach.fileName)}
                  </div>
                </div>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      );
    });
    return attachmentsToRender;
  };

  downloadFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.caseService.GetCaseAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  canUpload = (attachemntType: WorkflowDocumentType): boolean => {
    const files = this.state.caseAttachments.filter(
      (attach) => attach.attachmentTypeId === attachemntType.documentTypeId
    );

    if (!attachemntType.isMultiple && files.length >= 1) {
      return false;
    }

    return true;
  };

  generateReport = async (workflowDocumentType: WorkflowDocumentType, appointment: Appointment | null)  => {
    if (workflowDocumentType.documentType!.code === AttachmentTypeCode.INVOICE_REPAIR.toString()) {
      await this.generateInvoiceReport(workflowDocumentType);
    } else {
      if (appointment === null) { 
        // this.props.enqueueSnackbar(this.translatorService.Tranlate('NO_APPOINTMENT_FOUND', 'Nu s-a gasit programarea asociata!'), {
        //   variant: 'error'
        // });
        return;
      }
      await this.generateInOutReport(workflowDocumentType, appointment);
    }
  }

  generateInOutReport = async (workflowDocumentType: WorkflowDocumentType, appointment: Appointment) => {
    try {
      this.setState({
        attachmentTypeList: this.setAttachmentTypeList(workflowDocumentType, true)
      });
      //Get the In/Out Report
      const reportParameters = [
        {
          name: 'caseId',
          value: this.props.caseSettingsState.case!.id.toString()
        } as ReportParameter,
        {
          name: 'appointmentId',
          value: appointment.id.toString()
        },
        {
          name: 'organizationId',
          value: this.props.appState.appUser!.organization?.id.toString()
        } as ReportParameter,
        {
          name: 'type',
          value: workflowDocumentType.documentType!.code!
        } as ReportParameter
      ];

      const fileBlob = await this.reportsService.GetInOutReport(workflowDocumentType.documentType!.code!, this.props.caseSettingsState.case!.id, appointment.id, reportParameters);

      //Save the IN/OUT Report into attachments
      const attachments: CaseAttachment[] = [];
      attachments.push({
        id: 0,
        caseId: this.props.caseSettingsState.case!.id,
        attachmentTypeId: workflowDocumentType.documentType!.id,
        attachmentType: workflowDocumentType.documentType!,
        file: new File([fileBlob], workflowDocumentType.documentType!.displayName! + '.pdf', { type: 'application/pdf' }),
        isSelection: false,
        appointmentId: appointment.id
      } as CaseAttachment);

      console.log(attachments);
      const newFiles = await this.caseService.AddCaseAttachment(attachments);

      newFiles.forEach((item, index) => {
        item.file = attachments[index].file;
      });

      const caseAttachments = [...this.state.caseAttachments];
      caseAttachments.push(...newFiles);

      this.setState({
        caseAttachments: caseAttachments,
        attachmentTypeList: this.setAttachmentTypeList(workflowDocumentType, false)
      });

    }  catch (error) {
      console.log('error', error);
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  generateInvoiceReport = async (workflowDocumentType: WorkflowDocumentType) => {
    try {
      this.setState({
        attachmentTypeList: this.setAttachmentTypeList(workflowDocumentType, true)
      });
      //Get the invoice Data
      const reportParameters = [
        {
          name: 'caseId',
          value: this.props.caseSettingsState.case!.id.toString()
        } as ReportParameter,
        {
          name: 'organizationId',
          value: this.props.appState.appUser!.organization?.id.toString()
        } as ReportParameter
      ];

      const fileBlob = await this.reportsService.GetInvoiceReport(workflowDocumentType.documentType!.code!, this.props.caseSettingsState.case!.id, reportParameters);

      //Save the IN/OUT Report into attachments
      const attachments: CaseAttachment[] = [];
      attachments.push({
        id: 0,
        caseId: this.props.caseSettingsState.case!.id,
        attachmentTypeId: workflowDocumentType.documentType!.id,
        attachmentType: workflowDocumentType.documentType!,
        file: new File([fileBlob], workflowDocumentType.documentType!.displayName! + '.pdf', { type: 'application/pdf' }),
        isSelection: false
      } as CaseAttachment);

      console.log(attachments);
      const newFiles = await this.caseService.AddCaseAttachment(attachments);

      newFiles.forEach((item, index) => {
        item.file = attachments[index].file;
      });

      const caseAttachments = [...this.state.caseAttachments];
      caseAttachments.push(...newFiles);

      this.setState({
        caseAttachments: caseAttachments,
        attachmentTypeList: this.setAttachmentTypeList(workflowDocumentType, false)
      });

    }  catch (error) {
      console.log('error', error);
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  renderWorkflowAttashmentTypes = () => {
    const attachmentTypes = [] as any[];
    const attachmentTypeList = this.state.attachmentTypeList;
    if (attachmentTypeList.length !== 0) {
      attachmentTypeList.forEach((attach, index) => {
        attach.fileInputKey = Date.now();
        attachmentTypes.push(
          <div key={index} className="p-1 w-50 d-flex justify-content-center">
            <Card className="card-box w-100 shadow-none">
              <div className="card-header">
                <div className="card-header--title">
                  <b>{attach.isSelectionGroup ? 'Renew: ' : ''}</b>
                  <b>
                    {attach.documentType!.displayName}
                    {attach.isMultiple ? (
                      <IconButton
                        aria-label="download"
                        color="inherit"
                        size="small"
                        disabled={
                          this.state.caseAttachments.filter(
                            (item) => item.attachmentTypeId === attach.documentTypeId
                          ).length === 0
                        }
                        onClick={(e) => {
                          this.downloadBasedOnAttachmentType(attach);
                        }}
                      >
                        <GetAppIcon />
                      </IconButton>
                    ) : (
                      ''
                    )}
                  </b>
                </div>
              </div>

              <CardContent className="p-3 flex-grow-1">
                <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                  <ScaleLoader color={'var(--primary)'} loading={attach.isLoading} />
                </div>
                {!attach.isLoading ? (
                  <div className="file-manager-wrapper h-100">
                    {this.state.caseAttachments.filter(
                      (item) => item.attachmentTypeId === attach.documentTypeId
                    ).length !== 0 ? (
                      <div className="table-responsive">
                        <table className="table table-striped table-hover text-nowrap mb-0">
                          <tbody>
                            <tr>
                              <td>{this.renderAttachments(attach)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="h-100 align-self-center text-center">
                        <DescriptionIcon
                          className="h-100"
                          color="secondary"
                          fontSize="large"
                        ></DescriptionIcon>
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </CardContent>
              <CardFooter className="p-3 border-0">
                {(attach.documentType!.code === AttachmentTypeCode.WORK_ORDER.toString()  || 
                    attach.documentType!.code === AttachmentTypeCode.HAND_OVER.toString() || 
                    attach.documentType!.code === AttachmentTypeCode.INVOICE_REPAIR.toString()
                  )
                  ? ( 
                    this.state.caseAttachments.filter(
                      (item) => item.attachmentTypeId === attach.documentTypeId
                    ).length === 0 ? (
                      <ButtonGroup className="w-100">
                        <Button
                          variant="contained"
                          color="primary"
                          className="d-block w-100 text-center shadow-none"
                          disabled={!this.state.hasRights || !this.canUpload(attach)}
                          onClick={() => this.generateReport(attach, this.state.appointment)}
                        >
                        {attach.documentType!.code === AttachmentTypeCode.INVOICE_REPAIR.toString() ? this.translatorService.Tranlate('INVOICE_REPAIR_GENERATION_LABEL', 'Genereaza factura') : this.translatorService.Tranlate('IN_OUT_REPORT_GENERATION_LABEL', 'Genereaza raport') }
                        </Button>
                    </ButtonGroup>
                    ) : null
                ) : (
                  <ButtonGroup className="w-100">
                    <Button
                      style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                      variant="contained"
                      color="primary"
                      className="d-block w-100 text-center shadow-none"
                      disabled={!this.state.hasRights || !this.canUpload(attach)}
                    >
                      <input
                        className="d-none"
                        key={attach.fileInputKey}
                        id={'contained-button-file' + attach.documentTypeId}
                        disabled={!this.state.hasRights || !this.canUpload(attach)}
                        onChange={(e) => {
                          return this.handleUploadAttachment(e, attach);
                        }}
                        type="file"
                        multiple={attach.isMultiple}
                      />
                      <label
                        htmlFor={'contained-button-file' + attach.documentTypeId}
                        className="w-100 mb-0"
                      >
                        {this.translatorService.Tranlate(
                          'CASE_TAB_DOCUMENTS_FORM_UPLOAD',
                          'Upload'
                        )}
                      </label>
                    </Button>
                    <Tooltip
                      title={
                        !this.state.hasRights
                          ? ''
                          : this.translatorService.Tranlate(
                              'CASE_DATA_TAKE_PHOTO_TITLE',
                              'Fotografiaza'
                            )
                      }
                    >
                      <IconButton
                        component="div"
                        disabled={!this.state.hasRights || !this.canUpload(attach)}
                        style={{
                          border: '2px solid #5383ff',
                          borderTopRightRadius: '8px',
                          borderBottomRightRadius: '8px'
                        }}
                        onClick={(e: any) => this.setCameraDetails(attach)}
                        aria-label="photo"
                        color="primary"
                        size="medium"
                        className="p-0"
                      >
                        <PhotoCameraOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </ButtonGroup>
                )}
              </CardFooter>
            </Card>
          </div>
        );
      });
    }
    return attachmentTypes;
  };

  downloadAll = async () => {
    const fileBlob = await this.caseService.GetCaseAttachmentsArchive(
      this.props.caseSettingsState.case!.id,
      null,
      this.state.attachmentTypeList.map((item) => item.documentType!.code!)
    );
    FileSaver.saveAs(
      fileBlob,
      this.props.caseSettingsState.case!.id + '_' + this.props.workflowForm.displayName + '.zip'
    );
  };

  downloadBasedOnAttachmentType = async (item: WorkflowDocumentType) => {
    this.setState({
      attachmentTypeList: this.setAttachmentTypeList(item, true)
    });
    const fileBlob = await this.caseService.GetCaseAttachmentsArchive(
      this.props.caseSettingsState.case!.id,
      null,
      [item.documentType!.code!]
    );
    this.setState({
      attachmentTypeList: this.setAttachmentTypeList(item, false)
    });
    FileSaver.saveAs(
      fileBlob,
      this.props.caseSettingsState.case!.id + '_' + item.documentType!.displayName + '.zip'
    );
  };

  handleConfirmChanges = async () => {
    try {
      let error = false;
      this.state.attachmentTypeList.forEach((item) => {
        if (
          (item.isMandatory as unknown as boolean) === true &&
          this.state.caseAttachments.filter(
            (attach) => attach.attachmentTypeId === item.documentTypeId
          ).length === 0
        ) {
          this.props.enqueueSnackbar(
            item.documentType!.displayName +
              ' ' +
              this.translatorService.Tranlate(
                'CASE_DOCUMENTS_FORM_IS_MANDATORY',
                'este obligatoriu de adaugat!'
              ),
            { variant: 'error' }
          );
          error = true;
        }
        return;
      });

      this.setState({ executing: true });
      if (error === false) {


        if (this.props.workflowForm.form.hasActions) {
          await this.submitForm(
            this.props.caseSettingsState.case!.id,
            this.props.workflowForm.id,
            null
          );
        }

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      }
    } catch (ex) {
      this.props.enqueueSnackbar(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.translatorService.Tranlate(ex.response.data, 'Eroare incarcare documente!'),
        { variant: 'error' }
      );
    } finally {
      this.setState({ executing: false });
    }
  };

  carfixAttachmentNotification = async (attachmentType: IReferential | null, attachmentId: number) => {
    try {
      const request = {
        caseId: this.props.caseSettingsState.case!.id,
        workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
        attachmentId: attachmentId,
        attachmentTypeCode: attachmentType!.code!
      } as CarfixAttachmentNotificationRequest;
      const result = await this.caseService.CarfixAttachmentNotification(request) as CarfixAttachmentNotificationResponse;
      if (!result.success) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate(result.code, result.message), {
          variant: 'error'
        });
        return true;
      }
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
      return true;
    }
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    if (this.props.caseSettingsState.caseSettings!.workflow.isCarfixIntegration) {
      const attachment = this.state.caseAttachments.length > 0 ? this.state.caseAttachments[0] : null;
      if (attachment === null) {
        return;
      }
      const attachmentType = this.state.attachmentTypeList.find(
        (item) => item.documentTypeId === attachment.attachmentTypeId
      );
      const hasError = await this.carfixAttachmentNotification(attachmentType!.documentType, attachment.id);
      if (hasError) {
        return;
      }
    }

    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }
    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  closeDialog = async () => {
    this.setState({
      isDialogOpen: false
    });
  };

  closeCameraDialog = async () => {
    this.setState({
      isCameraDialogOpen: false,
      isSelection: false
    });
  };

  handleTakePhoto = async (dataUri: any) => {
    const arr = dataUri.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    let i = n;

    while (i--) {
      u8arr[i] = bstr.charCodeAt(i);
    }

    const files = [
      new File([u8arr], 'captured_image_' + Math.random().toString(36).slice(2) + '.png', {
        type: mime
      })
    ];
    this.handleUploadAttachmentFromCamera(files, this.state.attachmentTypeClicked);
  };

  public hasIntegrationDocuments = () => {
    return this.state.attachmentTypeList.some((item) => item.documentType!.code === AttachmentTypeCode.INVOICE_REPAIR.toString() || item.documentType!.code === AttachmentTypeCode.HAND_OVER.toString() || item.documentType!.code === AttachmentTypeCode.WORK_ORDER.toString());
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.reportsService = (this.context as AppContext).reportsService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center mb-2">
            {this.state.showUploadMessage ? (
              <div className="d-flex flex-wrap m-1">
                <div className="p-1 d-flex justify-content-center">
                  <b>
                    {this.translatorService.Tranlate(
                      'PHOTO_LIMIT_MESSAGE',
                      'Conditiile pentru 1 upload sunt urmatoarele : Numar Total Poze ≤ 80 si Dimensiune Totala ≤ 500MB .'
                    )}
                  </b>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="d-flex flex-wrap m-1">{this.renderWorkflowAttashmentTypes()}</div>
            <div className="text-left mb-2">
              <Button
                className="m-2"
                variant="contained"
                color="secondary"
                onClick={this.downloadAll}
                disabled={this.state.executing}
                hidden={this.state.caseAttachments.length === 0 || this.props.caseSettingsState.caseSettings!.workflow.isCarfixIntegration}
              >
                <GetAppIcon />{' '}
                {this.translatorService.Tranlate('CASE_DOCUMENTS_FORM_DOWNLOAD_ALL', 'Descarca')}
              </Button>
            </div>
            <br></br>

            <Button
              className="m-2"
              variant="contained"
              color="primary"
              onClick={this.handleConfirmChanges}
              disabled={!this.state.hasRights || this.state.executing}
            >
              {this.props.caseSettingsState?.caseSettings!.workflow?.isCarfixIntegration && this.hasIntegrationDocuments() ?  
                this.translatorService.Tranlate(
                  'TAB_DOCUMENTS_SEND_TO_CLIENT',
                  'Trimite la client'
                ) 
                : this.translatorService.Tranlate('SAVE', 'Salveaza')}
            </Button>
          </div>
        ) : null}

        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          maxWidth="md"
          fullWidth
          fullScreen={false}
          classes={{ paper: 'dialogPaper' }}
          scroll="body"
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate('CASE_PREVIEW_DOC', 'Previzualizare document')}
                </Typography>
              </Grid>
              <Grid className="text-right" item xs={1}>
                <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container alignContent="center">
              <Grid item xs={12}>
                <Box height="65vh" className="text-center">
                  {this.renderPreviewAttachment()}
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Modal open={this.state.isOpen} onClose={this.handleCloseModal}>
          <Dialog open={this.state.dialogVisible} onClose={this.handleCloseConfirmationDialog}>
            <DialogContent>
              <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {`${this.translatorService.Tranlate(
                  'DOCUMENT_DELETE_CONFIRMATION_MESSAGE',
                  'Sunteti sigur ca doriti sa stergeti documentul?'
                )} `}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => {
                  const selectedAttachment = this.state.selectedAttachment;
                  this.handleDeleteAttachment(
                    selectedAttachment.id,
                    selectedAttachment.fileName,
                    selectedAttachment.fileInputKey
                  );
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_YES', 'Da')}
              </Button>
              <Button onClick={() => this.handleCloseConfirmationDialog()}>
                {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
              </Button>
            </DialogActions>
          </Dialog>
        </Modal>

        <Dialog
          onClose={this.closeCameraDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isCameraDialogOpen}
          fullWidth
          maxWidth={'lg'}
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate('CASE_DATA_TAKE_PHOTO_TITLE', 'Fotografiaza')}
                </Typography>
              </Grid>
              <Grid className="text-right" item xs={1}>
                <IconButton aria-label="close" onClick={this.closeCameraDialog} size={'small'}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Camera
              onTakePhoto={(dataUri: any) => {
                this.handleTakePhoto(dataUri);
              }}
              onTakePhotoAnimationDone={(dataUri: any) => {
                this.closeCameraDialog();
              }}
              mirrored={true}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabDocumentsFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabDocumentsForm as any));
