import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import {
  Box,
  Button,
  Card,
  CardContent,
  Switch,
  FormControlLabel,
  Typography,
  IconButton,
  Grid,
  Divider
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../helpers/Constants';
import { WorkflowPartner, WorkflowCalculation } from '../../../interfaces/Workflow';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { IReferential } from '../../../interfaces/IReferential';
import { Input } from 'reactstrap';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { WrapperSimple } from '../../../layout-components';
import { isNullOrUndefined } from 'util';

interface IWorkflowCalculationState {
  workflowCalculations: WorkflowCalculation[];
  isLoading: boolean;
  workflowPartnerTypeList: WorkflowPartner[];
  selecteWorkflowPartnerType: WorkflowPartner | null;
  caseStatusTypeList: IReferential[];
  selectedCaseStatusType: IReferential | null;
  isSelectedWorkflowCalculation: boolean;
  newWorkflowCalculation: WorkflowCalculation | null;
  selectedUserRole: IReferential | null;
  userRoles: IReferential[];
}

export interface ExternalCalculationFormActionProps {
  workflowFormId: number;
}

type IWorkflowCalculationProps = ExternalCalculationFormActionProps & {
  appState: AppState;
} & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class WorkflowCalculations extends React.PureComponent<
  IWorkflowCalculationProps,
  IWorkflowCalculationState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    workflowCalculations: [],
    isLoading: false,
    workflowPartnerTypeList: [],
    selecteWorkflowPartnerType: null,
    caseStatusTypeList: [],
    selectedCaseStatusType: null,
    isSelectedWorkflowCalculation: false,
    newWorkflowCalculation: null,
    selectedUserRole: null,
    userRoles: []
  } as IWorkflowCalculationState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadWorkflowCalculations();
      }
    );
  }

  loadWorkflowCalculations = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    const ref = Referentials.referential.find((item) => item.code === ReferentialCode.CaseStatus);
    const refRole = Referentials.referential.find((item) => item.code === ReferentialCode.UserRole);

    const [workflowCalculations, workflowPartnerList, caseStatusTypes, userRoles] =
      await Promise.all([
        this.workflowService.GetWorkflowCalculations(this.props.workflowFormId),
        this.workflowService.GetWorkflowPartners(workflowId),
        this.appReferentialService.Get(ref!.baseUrl),
        this.appReferentialService.Get(refRole!.baseUrl)
      ]);

    const partners = await this.organizationService.GetOrganizationsByIds(
      workflowPartnerList.map((item) => item.partnerId)
    );
    workflowPartnerList.forEach((wpItem) => {
      const partner = partners.find((item) => item.id === wpItem.partnerId);
      wpItem.partner = isNullOrUndefined(partner) ? null : partner;
    });

    workflowCalculations.forEach((wcItem) => {
      const wc = workflowPartnerList.find((item) => item.id === wcItem.workflowPartnerId);
      const cs = caseStatusTypes.find((item) => item.id === wcItem.caseStatusId);

      wcItem.workflowPartner = isNullOrUndefined(wc) ? null : wc;
      wcItem.caseStatus = isNullOrUndefined(cs) ? null : cs;
    });

    this.setState({
      workflowCalculations: workflowCalculations,
      caseStatusTypeList: caseStatusTypes.filter((item) => item.isActive === true),
      workflowPartnerTypeList: workflowPartnerList.filter((item) => item.isActive === true),
      userRoles: userRoles.filter((item) => item.isActive === true),
      isLoading: false
    });
  };

  reloadWorkflowCalculation = async (organizationId: number | null) => {
    let workflowCalculations = await this.workflowService.GetWorkflowCalculations(
      this.props.workflowFormId
    );

    workflowCalculations.forEach((wcItem) => {
      const wc = this.state.workflowPartnerTypeList.find(
        (item) => item.id === wcItem.workflowPartnerId
      );
      const cs = this.state.caseStatusTypeList.find((item) => item.id === wcItem.caseStatusId);

      wcItem.workflowPartner = isNullOrUndefined(wc) ? null : wc;
      wcItem.caseStatus = isNullOrUndefined(cs) ? null : cs;
    });

    if (organizationId !== null) {
      workflowCalculations = [
        ...workflowCalculations.filter((item) => item.workflowPartnerId === organizationId)
      ];
    }
    this.setState({
      isLoading: false,
      workflowCalculations: workflowCalculations
    });
  };

  handleIsActiveChange = async (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const workflowCalculations = [...this.state.workflowCalculations];
    const updatedWorkflowCalculationIndex = workflowCalculations.findIndex((o) => o.id === id);

    if (updatedWorkflowCalculationIndex >= 0) {
      workflowCalculations[updatedWorkflowCalculationIndex] = {
        ...workflowCalculations[updatedWorkflowCalculationIndex],
        [event.target.id]: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflowCalculation(
          workflowCalculations[updatedWorkflowCalculationIndex]
        );

        this.setState({ workflowCalculations: workflowCalculations });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  onRowClick = (workflowCalculationId: number) => {
    const newWorkflowCalculation = {
      ...this.state.workflowCalculations.find((item) => item.id === workflowCalculationId)
    } as WorkflowCalculation;
    this.setState({
      newWorkflowCalculation: newWorkflowCalculation,
      isSelectedWorkflowCalculation: true
    });
  };

  filterCaseStatus = (workflowCalculations: any[]) => {
    //const caseStatusTypes = [{ id: 0, displayName: "Initiat" }, { id: 1, displayName: "Deviz OE" }, { id: 2, displayName: "Inchis" }];

    return this.state.caseStatusTypeList.filter(
      (item) => workflowCalculations.find((r) => r.caseStatusId === item.id) === undefined
    );
  };

  deleteCalculation = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const index = this.state.workflowCalculations.findIndex((i) => i.id === tableMeta.rowData[0]);
      const deletedCalculation = this.state.workflowCalculations[index];
      const newWorkflowCalculationsArray = [...this.state.workflowCalculations];
      newWorkflowCalculationsArray.splice(index, 1);

      await this.workflowService.RemoveWorkflowCalculation(deletedCalculation.id);

      this.setState({
        workflowCalculations: newWorkflowCalculationsArray
        // caseStatusTypeList: this.filterCaseStatus(newWorkflowCalculationsArray)
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'workflowPartner.partner.displayName',
        label: this.translatorService.Tranlate(
          'WORKFLOW_CALCULATION_TAB_ORGANIZATION_HEADER',
          'Organizatie'
        ),
        options: {
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className=" text-primary"
                onClick={(e) => this.onRowClick(tableMeta.rowData[0])}
              >
                <span>{value}</span>
              </Button>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseStatus',
        label: this.translatorService.Tranlate(
          'WORKFLOW_CALCULATION_TAB_CASE_STATUS_HEADER',
          'Status Dosar'
        ),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'userRole',
        label: this.translatorService.Tranlate('WORKFLOW_USER_ROLE_LABEL', 'Rol'),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return value ? value.displayName : '';
          }
        } as MUIDataTableColumnOptions
      },
      // {
      //     name: 'originalCalculation',
      //     label: this.translatorService.Tranlate("WORKFLOW_CALCULATION_TAB_ORIGINAL_CALCULATION_HEADER", "Calculatie Originala"),
      //     options: {
      //         filter: false,
      //         sort: false,
      //         // filterOptions: {
      //         //     names: [this.translatorService.Tranlate("TABLE_IS_ACTIVE_COLUMN_ACTIVE", "Activ"), this.translatorService.Tranlate("TABLE_IS_ACTIVE_COLUMN_INACTIVE", "Inactiv")],
      //         //     logic(isActive: any, filterVal: string[]) {
      //         //         const show =
      //         //             (filterVal.indexOf(this.names[0]) !== -1 && isActive === true) ||
      //         //             (filterVal.indexOf(this.names[1]) !== -1 && isActive === false);
      //         //         return !show;
      //         //     },
      //         // },
      //         customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
      //             return (
      //                 <Switch
      //                     id="originalCalculation"
      //                     checked={value ? value : false}
      //                     onChange={(e) => this.handleIsActiveChange(e, tableMeta.rowData[0])}
      //                     color="primary"
      //                     inputProps={{ 'aria-label': 'primary checkbox' }}
      //                 />)
      //         }

      //     }
      // },
      // {
      //     name: 'alternativeCalculation',
      //     label: this.translatorService.Tranlate("WORKFLOW_CALCULATION_TAB_ALTERNATIVE_CALCULATION_HEADER", "Calculatie Alternativa"),
      //     options: {
      //         filter: false,
      //         sort: false,
      //         // filterOptions: {
      //         //     names: [this.translatorService.Tranlate("TABLE_IS_ACTIVE_COLUMN_ACTIVE", "Activ"), this.translatorService.Tranlate("TABLE_IS_ACTIVE_COLUMN_INACTIVE", "Inactiv")],
      //         //     logic(isActive: any, filterVal: string[]) {
      //         //         const show =
      //         //             (filterVal.indexOf(this.names[0]) !== -1 && isActive === true) ||
      //         //             (filterVal.indexOf(this.names[1]) !== -1 && isActive === false);
      //         //         return !show;
      //         //     },
      //         // },
      //         customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
      //             return (
      //                 <Switch
      //                     id="alternativeCalculation"
      //                     checked={value ? value : false}
      //                     onChange={(e) => this.handleIsActiveChange(e, tableMeta.rowData[0])}
      //                     color="primary"
      //                     inputProps={{ 'aria-label': 'primary checkbox' }}
      //                 />)
      //         }

      //     }
      // },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'WORKFLOW_CALCULATION_TAB_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          filter: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().format(this.props.appState.longDateFormat)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteCalculation(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  addWorkflowCalcType = async () => {
    try {
      if (
        this.state.workflowCalculations.findIndex(
          (item) =>
            item.workflowPartnerId === this.state.selecteWorkflowPartnerType!.id &&
            ((this.state.selectedUserRole !== null &&
              item.userRoleId === this.state.selectedUserRole!.id) ||
              (this.state.selectedUserRole === null && item.userRoleId !== null) ||
              (this.state.selectedUserRole !== null && item.userRoleId === null) ||
              (this.state.selectedUserRole === null && item.userRoleId === null)) &&
            item.caseStatusId === this.state.selectedCaseStatusType!.id
        ) !== -1
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_MSG_WORKFLOW_CALCULATION_EXISTS',
            'Calculatia exista deja!'
          ),
          { variant: 'error' }
        );
        return;
      }

      await this.workflowService.AddWorkflowCalculation({
        id: 0,
        workflowSectionStepFormId: this.props.workflowFormId,
        workflowPartnerId: this.state.selecteWorkflowPartnerType
          ? this.state.selecteWorkflowPartnerType!.id
          : 0,
        caseStatusId: this.state.selectedCaseStatusType ? this.state.selectedCaseStatusType!.id : 0,
        userRoleId: this.state.selectedUserRole ? this.state.selectedUserRole.id : null
      } as WorkflowCalculation);

      this.setState(
        {
          isLoading: true,
          selectedCaseStatusType: null,
          selectedUserRole: null
        },
        async () => {
          await this.reloadWorkflowCalculation(this.state.selecteWorkflowPartnerType!.id);
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleCaseStatusChange = (newValue: any | null) => {
    this.setState({ selectedCaseStatusType: newValue });
  };
  handleUserRoleChange = (newValue: any | null) => {
    this.setState({ selectedUserRole: newValue });
  };
  handleWorkflowPartnerChange = (newValue: WorkflowPartner | null) => {
    this.setState(
      {
        selecteWorkflowPartnerType: newValue,
        isLoading: true
      },
      async () => {
        await this.reloadWorkflowCalculation(newValue ? newValue.id : null);
      }
    );
  };

  renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addWorkflowCalcType}>
              <Autocomplete
                id="workflowPartner"
                className="m-2"
                options={this.state.workflowPartnerTypeList.sort(function (a, b) {
                  return a.partner!.displayName.localeCompare(b.partner!.displayName);
                })}
                value={this.state.selecteWorkflowPartnerType}
                onChange={(e: any, newValue: WorkflowPartner | null) =>
                  this.handleWorkflowPartnerChange(newValue)
                }
                getOptionLabel={(option: WorkflowPartner) => option.partner!.displayName || ''}
                filterOptions={createFilterOptions({
                  matchFrom: 'any',
                  stringify: (option: WorkflowPartner) => option.partner!.displayName
                })}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="workflowPartner"
                    value={this.state.selecteWorkflowPartnerType}
                    label={this.translatorService.Tranlate(
                      'WORKFLOW_CALCULATION_TAB_ORGANIZATION_LABEL',
                      'Organizatie'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
              <Autocomplete
                id="caseStatus"
                className="m-2"
                options={this.state.caseStatusTypeList.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedCaseStatusType}
                onChange={(e: any, newValue: any | null) => this.handleCaseStatusChange(newValue)}
                getOptionLabel={(option: any) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="caseStatus"
                    value={this.state.selectedCaseStatusType}
                    label={this.translatorService.Tranlate(
                      'WORKFLOW_CALCULATION_TAB_CASE_STATUS_LABEL',
                      'Status'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
              <Autocomplete
                id="userRole"
                className="m-2"
                options={this.state.userRoles.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedUserRole}
                onChange={(e: any, newValue: any | null) => this.handleUserRoleChange(newValue)}
                getOptionLabel={(option: any) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="userRole"
                    value={this.state.selectedUserRole}
                    label={this.translatorService.Tranlate('WORKFLOW_USER_ROLE_LABEL', 'Rol')}
                    fullWidth
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  saveCalculationsChange = async () => {
    const workflowCalculations = [...this.state.workflowCalculations];
    const updatedWorkflowCalculationIndex = workflowCalculations.findIndex(
      (o) => o.id === this.state.newWorkflowCalculation!.id
    );

    if (updatedWorkflowCalculationIndex >= 0) {
      workflowCalculations[updatedWorkflowCalculationIndex] = this.state.newWorkflowCalculation!;

      try {
        await this.workflowService.UpdateWorkflowCalculation(this.state.newWorkflowCalculation!);

        this.setState({
          workflowCalculations: workflowCalculations,
          selectedCaseStatusType: null,
          newWorkflowCalculation: null,
          isSelectedWorkflowCalculation: false,
          selectedUserRole: null
        });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };
  renderCalculations = () => {
    return (
      <WrapperSimple
        sectionHeading={
          this.state.newWorkflowCalculation!.workflowPartner!.partner!.name +
          ' - ' +
          this.state.newWorkflowCalculation!.caseStatus!.displayName
        }
      >
        <ValidatorForm onSubmit={(e) => this.saveCalculationsChange()}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <Card className="mb-4 h-100">
                <div className="card-body px-2 pb-2 pt-3 text-center">
                  <h6 className="my-4 font-weight-bold">
                    {this.translatorService.Tranlate(
                      'WORKFLOW_CALCULATION_TAB_CONFIGURE_CALCULATION_LIST_LABEL',
                      'Servicii GTM'
                    )}
                  </h6>
                  <Divider />
                  <div className="d-flex flex-column align-items-start mr-4 mt-3">
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="originalCalculation"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.originalCalculation
                              ? this.state.newWorkflowCalculation!.originalCalculation
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                originalCalculation: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_ORIGINAL_CALCULATION_LABEL',
                            'Calculatie Originala'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="vinInterogation"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.vinInterogation
                              ? this.state.newWorkflowCalculation!.vinInterogation
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                vinInterogation: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_VIN_INTEROGATION_LABEL',
                            'Interogare VIN'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="hideEconomicParts"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.hideEconomicParts
                              ? this.state.newWorkflowCalculation!.hideEconomicParts
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                hideEconomicParts: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_HIDE_ECONOMIC_PARTS_LABEL',
                            'Ascunde piese de tip Economic'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="alternativeCalculation"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.alternativeCalculation
                              ? this.state.newWorkflowCalculation!.alternativeCalculation
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                alternativeCalculation: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_ALTERNATIVE_CALCULATION_LABEL',
                            'Calculatie alternativa'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="gtValuation"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.gtValuation
                              ? this.state.newWorkflowCalculation!.gtValuation
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                gtValuation: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_GT_VALUATION_LABEL',
                            'GT Valuation'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="compare"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.compare
                              ? this.state.newWorkflowCalculation!.compare
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                compare: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_COMPARE_LABEL',
                            'Compara'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="qualityControl"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.qualityControlCertificate
                              ? this.state.newWorkflowCalculation!.qualityControlCertificate
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                qualityControlCertificate: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_QUALITY_CONTROL_LABEL',
                            'Control calitate'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="radar"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation?.radar
                              ? this.state.newWorkflowCalculation?.radar
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                radar: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_RADAR_LABEL',
                            'Radar'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                     <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="insurerCalculation"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.insurerCalculation
                              ? this.state.newWorkflowCalculation!.insurerCalculation
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                insurerCalculation: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_UPDATE_CALCULATION_LABEL',
                            'Actualizare calculatie cu tarife asigurator'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="hasMultipleTariff"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.hasMultipleTariff
                              ? this.state.newWorkflowCalculation!.hasMultipleTariff
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                hasMultipleTariff: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_HAS_MULTIPLE_TARIFF_LABEL',
                            'Tarife multiple'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                     <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="hasBackButton"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.hasBackButton
                              ? this.state.newWorkflowCalculation!.hasBackButton
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                hasBackButton: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_HAS_BACK_BUTTON_LABEL',
                            'Buton de revenire'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Card className="mb-4 h-100">
                <div className="card-body px-2 pb-2 pt-3 text-center">
                  <h6 className="my-4 font-weight-bold">
                    {this.translatorService.Tranlate(
                      'WORKFLOW_CALCULATION_TAB_ALTERNATIVE_CALCULATION_LABEL',
                      'Calculatie alternativa'
                    )}
                  </h6>
                  <Divider />
                  <div className="d-flex flex-column align-items-start mr-4 mt-3">
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="editGuideNumber"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.editGuideNumber
                              ? this.state.newWorkflowCalculation!.editGuideNumber
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                editGuideNumber: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_EDIT_GUIDE_NUMBER_LABEL',
                            'Modifica Guide Number Manual'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="editQuantity"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.editQuantity
                              ? this.state.newWorkflowCalculation!.editQuantity
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                editQuantity: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_EDIT_QUANTITY_LABEL',
                            'Modifica Cantitate Manual'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="addOriginalPart"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.addOriginalPart
                              ? this.state.newWorkflowCalculation!.addOriginalPart
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                addOriginalPart: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_ADD_ORIGINAL_PART_LABEL',
                            'Adauga piese originale'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="addAlternativePart"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.addAlternativePart
                              ? this.state.newWorkflowCalculation!.addAlternativePart
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                addAlternativePart: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_ADD_ALTERNATIV_PART_LABEL',
                            'Adauga Piese Alternative'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="deleteOriginalPart"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.deleteOriginalPart
                              ? this.state.newWorkflowCalculation!.deleteOriginalPart
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                deleteOriginalPart: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_DELETE_ORIGINAL_PART_LABEL',
                            'Sterge Piese Originale Manual'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="importOriginalParts"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.importOriginalParts
                              ? this.state.newWorkflowCalculation!.importOriginalParts
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                importOriginalParts: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_IMPORT_ORIGINAL_PARTS_LABEL',
                            'Importa Piese Originale Fisier'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="importAlternativeParts"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.importAlternativeParts
                              ? this.state.newWorkflowCalculation!.importAlternativeParts
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                importAlternativeParts: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_IMPORT_ALTERNATIVE_PART_LABEL',
                            'Importa Piese Alternative Fisier'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="selectAlternativePart"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.selectAlternativePart
                              ? this.state.newWorkflowCalculation!.selectAlternativePart
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                selectAlternativePart: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_SELECT_ALTERNATIV_PART_LABEL',
                            'Selecta Manual Piese Alternative'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="tmlEditable"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.tmlEditable
                              ? this.state.newWorkflowCalculation!.tmlEditable
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                tmlEditable: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_TML_EDITABLE_LABEL',
                            'TML Editabil'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100 mr-1 "
                      control={
                        <Input
                          type="number"
                          id="defaultMaxDeliveryTime"
                          value={
                            this.state.newWorkflowCalculation!.defaultMaxDeliveryTime
                              ? this.state.newWorkflowCalculation!.defaultMaxDeliveryTime
                              : ''
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                defaultMaxDeliveryTime: parseInt(e.target.value)
                              }
                            })
                          }
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_DEFAULT_MAX_DELIVERY_TIME_LABEL',
                            'Timp Maxim de Livrare Implicit'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="alternativeCalculationWithoutOriginalEditable"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.alternativeCalculationWithoutOriginal
                              ? this.state.newWorkflowCalculation!
                                  .alternativeCalculationWithoutOriginal
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                alternativeCalculationWithoutOriginal: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_ALTERNATIVE_WITHOUT_ORIGINAL_EDITABLE_LABEL',
                            'Alternativa fara Calculatie Originala'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="interogatePartnerEditable"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.interogatePartner
                              ? this.state.newWorkflowCalculation!.interogatePartner
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                interogatePartner: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_INTEROGATE_PARTNER_EDITABLE_LABEL',
                            'Interogare la Partener'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="alternativePartnerEditable"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.alternativePartner
                              ? this.state.newWorkflowCalculation!.alternativePartner
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                alternativePartner: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_ALTERNATIVE_PARTNER_EDITABLE_LABEL',
                            'Alternativa la Partener'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="alternativeNetPrice"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.alternativeNetPrice
                              ? this.state.newWorkflowCalculation!.alternativeNetPrice
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                alternativeNetPrice: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_ALTERNATIVE_LKQ_NET_PRICE_EDITABLE_LABEL',
                            'Pret Net LKQ Alternativ'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                     <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="viewOptim"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.viewOptim
                              ? this.state.newWorkflowCalculation!.viewOptim
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                viewOptim: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_VIEW_OPTIM_EDITABLE_LABEL',
                            'Buton Optim'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="viewPrcNewColumns"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.viewPrcNewColumns
                              ? this.state.newWorkflowCalculation!.viewPrcNewColumns
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                viewPrcNewColumns: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_VIEW_PRC_COLUMNS_EDITABLE_LABEL',
                            'Coloane noi PRC'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="serviceOwnStock"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.serviceOwnStock
                              ? this.state.newWorkflowCalculation!.serviceOwnStock
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                serviceOwnStock: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_SERVICE_OWN_STOCK_EDITABLE_LABEL',
                            'Stoc propriu service'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="oilMapping"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.oilMapping
                              ? this.state.newWorkflowCalculation!.oilMapping
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                oilMapping: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_OIL_MAPPING_EDITABLE_LABEL',
                            'Mapare uleiuri'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="allPartsHasBeenInterogated"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.allPartsHasBeenInterogated
                              ? this.state.newWorkflowCalculation!.allPartsHasBeenInterogated
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                allPartsHasBeenInterogated: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_SERVICE_ALL_PARTS_INTEROGATED_LABEL',
                            'Mesaj daca nu are toate piesele interogate'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="ViewClientCommentsHistory"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.viewClientCommentsHistory
                              ? this.state.newWorkflowCalculation!.viewClientCommentsHistory
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                viewClientCommentsHistory: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_SERVICE_VIEW_CLIENT_COMMENTS_HISTORY_LABEL',
                            'Istoric comentarii client pe deviz'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                                       <FormControlLabel
                      className="d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="byAquisitionPrice"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.byAquisitionPrice
                              ? this.state.newWorkflowCalculation!.byAquisitionPrice
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                byAquisitionPrice: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_SERVICE_BY_AQUISITION_PRICE_LABEL',
                            'Optim bazat pe pretul de achizitie'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card className="mb-4 h-100">
                <div className="card-body px-2 pb-2 pt-3 text-center">
                  <h6 className="my-4 font-weight-bold">
                    {this.translatorService.Tranlate(
                      'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_LABEL',
                      'Detalii Calculatie'
                    )}
                  </h6>
                  <Divider />
                  <div className="d-flex flex-column align-items-start mr-4 mt-2">
                    <Typography
                      variant="body2"
                      color="secondary"
                      className="font-weight-bold mt-2 ml-3"
                      align="left"
                    >
                      {this.translatorService.Tranlate(
                        'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_CAR_PARTS_LABEL',
                        'PIESE'
                      )}
                    </Typography>
                    <FormControlLabel
                      className="ml-4 pl-2 pr-2 mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="addCarParts"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.addCarParts
                              ? this.state.newWorkflowCalculation!.addCarParts
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                addCarParts: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_ADD_LABEL',
                            'Adauga'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="ml-4 pl-2 pr-2 mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="deleteCarParts"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.deleteCarParts
                              ? this.state.newWorkflowCalculation!.deleteCarParts
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                deleteCarParts: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_DELETE_LABEL',
                            'Sterge'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="ml-4 pl-2 pr-2 mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="editCarParts"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.editCarParts
                              ? this.state.newWorkflowCalculation!.editCarParts
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                editCarParts: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_EDIT_LABEL',
                            'Editeaza'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <Typography
                      variant="body2"
                      color="secondary"
                      className="font-weight-bold my-2 ml-3"
                      align="left"
                    >
                      {this.translatorService.Tranlate(
                        'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_LABOR_LABEL',
                        'MANOPERA'
                      )}
                    </Typography>
                    <FormControlLabel
                      className="ml-4 pl-2 pr-2 mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="addLabor"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.addLabor
                              ? this.state.newWorkflowCalculation!.addLabor
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                addLabor: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_ADD_LABEL',
                            'Adauga'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="ml-4 pl-2 pr-2 mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="deleteLabor"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.deleteLabor
                              ? this.state.newWorkflowCalculation!.deleteLabor
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                deleteLabor: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_DELETE_LABEL',
                            'Sterge'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="ml-4 pl-2 pr-2 mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="editLabor"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.editLabor
                              ? this.state.newWorkflowCalculation!.editLabor
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                editLabor: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_EDIT_LABEL',
                            'Editeaza'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <Typography
                      variant="body2"
                      color="secondary"
                      className="font-weight-bold my-2 ml-3"
                      align="left"
                    >
                      {this.translatorService.Tranlate(
                        'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_PAINTING_MATERIALS_LABEL',
                        'MATERIALE VOPSITORIE'
                      )}
                    </Typography>
                    <FormControlLabel
                      className="ml-4 pl-2 pr-2 mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="addPaintingMaterials"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.addPaintingMaterials
                              ? this.state.newWorkflowCalculation!.addPaintingMaterials
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                addPaintingMaterials: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_ADD_LABEL',
                            'Adauga'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="ml-4 pl-2 pr-2 mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="deletePaintingMaterials"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.deletePaintingMaterials
                              ? this.state.newWorkflowCalculation!.deletePaintingMaterials
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                deletePaintingMaterials: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_DELETE_LABEL',
                            'Sterge'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="ml-4 pl-2 pr-2 mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="editPaintingMaterials"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.editPaintingMaterials
                              ? this.state.newWorkflowCalculation!.editPaintingMaterials
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                editPaintingMaterials: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_EDIT_LABEL',
                            'Editeaza'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <Typography
                      variant="body2"
                      color="secondary"
                      className="font-weight-bold my-2 ml-3"
                      align="left"
                    >
                      {this.translatorService.Tranlate(
                        'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_PAINTING_LABOR_LABEL',
                        'MANOPERA VOPSITORIE'
                      )}
                    </Typography>
                    <FormControlLabel
                      className="ml-4 pl-2 pr-2 mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="addPaintingLabor"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.addPaintingLabor
                              ? this.state.newWorkflowCalculation!.addPaintingLabor
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                addPaintingLabor: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_ADD_LABEL',
                            'Adauga'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="ml-4 pl-2 pr-2 mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="deletePaintingLabor"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.deletePaintingLabor
                              ? this.state.newWorkflowCalculation!.deletePaintingLabor
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                deletePaintingLabor: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_DELETE_LABEL',
                            'Sterge'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="ml-4 pl-2 pr-2 mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="editPaintingLabor"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.editPaintingLabor
                              ? this.state.newWorkflowCalculation!.editPaintingLabor
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                editPaintingLabor: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_EDIT_LABEL',
                            'Editeaza'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />

                    <FormControlLabel
                      className="mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="importParts"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.importParts
                              ? this.state.newWorkflowCalculation!.importParts
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                importParts: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_IMPORT_CAR_PARTS_LABEL',
                            'Importa Piese'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="importParts"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.ownerSolutionView
                              ? this.state.newWorkflowCalculation!.ownerSolutionView
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                ownerSolutionView: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_OWNER_SOLUTION_VIEW_LABEL',
                            'Solutie Proprietar-Citire'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      className="mb-0 d-flex justify-content-between w-100"
                      control={
                        <Switch
                          id="importParts"
                          size="small"
                          checked={
                            this.state.newWorkflowCalculation!.ownerSolution
                              ? this.state.newWorkflowCalculation!.ownerSolution
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              newWorkflowCalculation: {
                                ...this.state.newWorkflowCalculation!,
                                ownerSolution: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate(
                            'WORKFLOW_CALCULATION_TAB_CALCULATION_DATA_OWNER_SOLUTION_LABEL',
                            'Solutie Proprietar'
                          )}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
          <div></div>
          <div className="text-right">
            <Button
              className="m-2"
              variant="outlined"
              color="primary"
              onClick={(e) =>
                this.setState({
                  selectedCaseStatusType: null,
                  newWorkflowCalculation: null,
                  isSelectedWorkflowCalculation: false
                })
              }
            >
              {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
            </Button>
            <Button className="m-2" variant="contained" color="primary" type="submit">
              {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
            </Button>
          </div>
        </ValidatorForm>
      </WrapperSimple>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: true,
      search: true,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        {/* <Box>
                    <Card>
                        <CardContent>
                            <Tabs
                                value={0}
                                indicatorColor="primary"
                                textColor="secondary"
                                variant="standard"
                                color="primary"
                                aria-label="disabled tabs example">
                                <Tab className="text-capitalize" label={this.translatorService.Tranlate("WORKFLOWS_TAB_CALCULATIONS", "Calculatii")} />
                            </Tabs>
                        </CardContent>
                    </Card>
                </Box> */}
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            this.state.isSelectedWorkflowCalculation === false ? (
              <MUIDataTable
                title={''}
                data={this.state.workflowCalculations}
                columns={this.getColumns()}
                options={options}
              />
            ) : (
              this.renderCalculations()
            )
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCalculationFormActionProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  null,
  mergeProps
)(withSnackbar(WorkflowCalculations as any));
