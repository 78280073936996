import * as React from 'react';
import { ApplicationState, AppState, CaseSettingsActionCreators, CaseSettingsState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { Case, CaseFilters, TariffPercentResponse } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { Card, Grid, IconButton, Tab, Tabs, AppBar, TextField, Tooltip, Button, Dialog, DialogTitle, DialogContent, Typography } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import TabPanel from '../_shared/Tabs';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import moment from 'moment';
import { IReferential } from '../../interfaces/IReferential';
import { CaseStatusTypeCode, FilterTypeCodeEnum, OrderStatusCode, ReferentialCode, SupplierCode } from '../../helpers/Constants';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { Organization, OrganizationFilterType } from '../../interfaces/Organization';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AppUser } from '../../interfaces/AppUser';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';
import { Workflow } from '../../interfaces/Workflow';
import { withSnackbar, ProviderContext } from 'notistack';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import * as R from 'ramda';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { Vehicle } from '../../interfaces/Vehicle';
import { VehicleHelper } from '../../helpers/VehicleHelper';
import Loader from '../Loader';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import _ from 'lodash';
import { Order } from '../../interfaces/Order';
import { IOrderService } from '../../services/Interfaces/IOrderService';
import CloseIcon from '@material-ui/icons/Close';
import OrderDetails from '../orders/OrderDetails';
import { CaseSettings } from '../../interfaces/CaseSettings';
import Referentials from '../../helpers/Referentials.json';

type CasesProps = {
    appState: AppState;
    caseSettingsState: CaseSettingsState;
  } & ProviderContext &
    RouteComponentProps;

interface IWorkflow {
  workflowId: number;
  workflowDisplayName: string;
}

interface ICasesState {
  caseList: Case[];
  organizationEntities: Organization[];
  selectedOrgEntities: Organization[];
  selectedOrgFilter: OrganizationFilterType | null;
  userWorkflowsList: Workflow[];
  workflowsList: IWorkflow[];
  selectedWorkflows: IWorkflow[];
  organizationCasePartnerList: Organization[];
  selectedOrgCasePartner: Organization[];
  caseStatusList: IReferential[];
  selectedCaseStatus: IReferential[];
  caseTypesList: IReferential[];
  selectedCaseType: IReferential[];
  assignedToUserList: AppUser[];
  createdByUserList: AppUser[];
  selectedAssignedToUser: AppUser[];
  selectedCreatedByUser: AppUser[];
  casePolicyTypesList: IReferential[];
  selectedPolicyType: IReferential[];
  selectedTab: number;
  isLoading: boolean;
  isLoadingCases: boolean;
  filterStartDate: Date | null;
  filterEndDate: Date | null;
  count: number;
  page: number;
  pageSize: number;
  showCopyCase: boolean;
  orderList: Order[];
  isDialogOpen: boolean;
  isLoadingDialog: boolean;
  isOrderDialogOpen: boolean;
  selectedOrder: Order | null;
  selectedCase: Case | null;
  showOrderDetails: boolean;
  vinSearchText: string;
  plateNumberSearchText: string;
  caseNumberSearchText: string;
  showCaseStatus: boolean;
  hidePartnerTab: boolean;
  makeSearchText: string;
  modelSearchText: string;
  showMakeModelSearch: boolean;
  hideLkqColumns: boolean;
}

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

class Cases extends React.PureComponent<CasesProps, ICasesState> {
  private workflowService!: IWorkflowService;
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appUserService!: IAppUserService;
  private organizationService!: IOrganizationService;
  private vehicleService!: IVehicleService;
  private orderService!: IOrderService;
  static contextType = ApplicationContext;
  private appReferentialService!: IReferentialService;

  state = {
    caseList: [],
    organizationEntities: [],
    selectedOrgEntities: [],
    workflowsList: [],
    userWorkflowsList: [],
    selectedWorkflows: [],
    organizationCasePartnerList: [],
    selectedOrgFilter: null,
    selectedOrgCasePartner: [],
    caseStatusList: [],
    selectedCaseStatus: [],
    caseTypesList: [],
    selectedCaseType: [],
    assignedToUserList: [],
    createdByUserList: [],
    selectedAssignedToUser: [],
    selectedCreatedByUser: [],
    casePolicyTypesList: [],
    selectedPolicyType: [],
    selectedTab: 0,
    isLoading: false,
    isLoadingCases: false,
    count: 1,
    page: 0,
    pageSize: 10,
    filterStartDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
    filterEndDate: new Date(),
    showCopyCase: false,
    orderList: [],
    isDialogOpen: false,
    isLoadingDialog: false,
    isOrderDialogOpen: false,
    selectedOrder: null,
    selectedCase: null,
    showOrderDetails: false,
    vinSearchText: '',
    plateNumberSearchText: '',
    caseNumberSearchText: '',
    showCaseStatus: false,
    hidePartnerTab: false,
    makeSearchText: '',
    modelSearchText: '',
    showMakeModelSearch: false,
    hideLkqColumns: false
  } as ICasesState;

  public async componentDidMount() {
    const filters = {
      assignedToList: [],
      createdByList: [],
      caseTypeIds: [],
      partnerIds: [],
      statusIds: [],
      workflowIds: [],
      organizationIds: [],
      page: this.state.page + 1,
      pageSize: this.state.pageSize,
      isInternal: this.isInternal(),
      text: '',
      isNew: false,
      dateFilterCode: FilterTypeCodeEnum.DS.toString(),
      dateFilterStartDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
      datefilterEndDate: new Date(),
      vehicleIds: [],
      policyTypeIds: [],
      vinSearchText: '',
      plateNumberSearchText: '',
      caseNumberSearchText: '',
      countryCode: null,
      isAssignedToId: false,
      makeSearchText: '',
      modelSearchText: '',
      showMakeModelSearch: false,
      hideLkqColumns: false
    } as CaseFilters;

    this.setState({
      isLoading: true
    });

    const refpolicyType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.PolicyType
    );
    const [cases, workflows, policyTypes] = await Promise.all([
      this.caseService.GetCases(filters),
      this.workflowService.GetUserWorkflows(),
      this.appReferentialService.Get(refpolicyType!.baseUrl)
    ]);
    const caseDetailsList = cases;

    const [usersAssignedLIst, users, orgOwners, vehicles] = await Promise.all([
      this.appUserService.GetUsersInfo(
        Array.from(new Set(cases.cases.map((item) => item.caseStatus.assignedToId)))
      ),
      this.appUserService.GetUsersInfo(
        Array.from(new Set(cases.cases.map((item) => item.caseStatus.userId)))
      ),
      this.organizationService.GetUserOrganizationsByIds(
        Array.from(new Set(cases.cases.map((item) => item.organizationOwnerId)))
      ),
      this.vehicleService.GetVehicleList(
        Array.from(
          new Set(cases.cases.filter((c) => !R.isNil(c.vehicleId)).map((item) => item.vehicleId!))
        )
      )
    ]);

    let organization: Organization | null = null;
    if (!R.isNil(this.props.appState!.appUser!.organizationId)) {
      organization = await this.organizationService.GetOrganizationInfo(
        this.props.appState!.appUser!.organizationId
      );
    }

    users.push(...usersAssignedLIst);
    this.materializeCaseObject(caseDetailsList.cases, workflows, vehicles, users, orgOwners);

    this.setState(
      {
        caseList: caseDetailsList.cases,
        userWorkflowsList: workflows,
        count: cases.total,
        page: cases.page - 1,
        showCopyCase: organization!.newFeature,
        showOrderDetails: organization!.showOrderDetails,
        showCaseStatus: organization!.showCaseStatus,
        hidePartnerTab: organization!.hidePartnerTab,
        showMakeModelSearch: organization!.showMakeModelSearch,
        hideLkqColumns: organization!.hideLkqColumns,
        casePolicyTypesList: policyTypes
      },
      async () => {
        await this.reloadFilters();
      }
    );
  }

  isInternal = (): boolean | null => {
    if (this.state.selectedTab === 0) {
      return null;
    }
    if (this.state.selectedTab === 1) {
      return false;
    }

    return true;
  };

  loadCases = async (page: number, rowsPerPage: number) => {
    this.setState({ isLoadingCases: true });
    const filters = {
      assignedToList: this.state.selectedAssignedToUser.map((item) => item.id),
      createdByList: this.state.selectedCreatedByUser.map((item) => item.id),
      caseTypeIds: this.state.selectedCaseType.map((item) => item.id),
      partnerIds: this.state.selectedOrgCasePartner.map((item) => item.id),
      statusIds: this.state.selectedCaseStatus.map((item) => item.id),
      workflowIds: this.state.selectedWorkflows.map((item) => item.workflowId),
      organizationIds: this.state.selectedOrgEntities.map((item) => item.id),
      policyTypeIds: this.state.selectedPolicyType.map((item) => item.id),
      isInternal: this.isInternal(),
      page: page + 1,
      pageSize: rowsPerPage,
      text: '',
      isNew: false,
      dateFilterCode:
        this.state.selectedOrgFilter === null ? FilterTypeCodeEnum.DS.toString() : this.state.selectedOrgFilter!.filterType!.code,
      dateFilterStartDate: this.state.filterStartDate
        ? new Date(this.state.filterStartDate.setHours(0, 0, 0, 0))
        : null,
      datefilterEndDate: this.state.filterEndDate
        ? new Date(this.state.filterEndDate.setHours(23, 59, 59, 999))
        : null,
      plateNumberSearchText: this.state.plateNumberSearchText.replace(/\s+/g, ''),
      vinSearchText: this.state.vinSearchText,
      caseNumberSearchText: this.state.caseNumberSearchText,
      makeSearchText: this.state.makeSearchText,
      modelSearchText: this.state.modelSearchText,
    } as CaseFilters;

    const caseList = await this.caseService.GetCases(filters);
    const [users, orgOwners, vehicles] = await Promise.all([
      this.appUserService.GetUsersInfo(
        Array.from(new Set(caseList.cases.map((item) => item.caseStatus.userId)))
      ),
      this.organizationService.GetUserOrganizationsByIds(
        Array.from(new Set(caseList.cases.map((item) => item.organizationOwnerId)))
      ),
      this.vehicleService.GetVehicleList(
        Array.from(
          new Set(
            caseList.cases.filter((c) => !R.isNil(c.vehicleId)).map((item) => item.vehicleId!)
          )
        )
      )
    ]);

    this.materializeCaseObject(
      caseList.cases,
      this.state.userWorkflowsList,
      vehicles,
      users,
      orgOwners
    );

    this.setState({
      caseList: caseList.cases,
      page: caseList.page - 1,
      pageSize: rowsPerPage,
      count: caseList.total,
      isLoadingCases: false
    });
  };

  materializeCaseObject = (
    cases: Case[],
    workflows: Workflow[],
    vehicles: Vehicle[],
    users: AppUser[],
    organizations: Organization[]
  ) => {
    cases.forEach((cd) => {
      const workflow = workflows.find((item) => item.id === cd.caseWorkflow.workflowId);
      const userAssignedTo = users.find((item) => item.id === cd.caseStatus.assignedToId);
      const user = users.find((item) => item.id === cd.caseStatus.userId);
      const organizationOwner = organizations.find((item) => item.id === cd.organizationOwnerId);
      const vehicle = vehicles.find((item) => !R.isNil(cd.vehicleId) && item.id === cd.vehicleId);

      cd.caseWorkflow.workflowDisplayName = R.isNil(workflow) ? '' : workflow.displayName;
      cd.caseStatus.assignedTo = R.isNil(userAssignedTo) ? null : userAssignedTo;
      cd.caseStatus.user = R.isNil(user) ? null : user;
      cd.organizationOwner = R.isNil(organizationOwner) ? null : organizationOwner;
      cd.vehicle = R.isNil(vehicle) ? null : vehicle;
    });
  };

  reloadFilters = async () => {
    const filters = await this.caseService.GetCaseFilters({
      assignedToList: [],
      createdByList: [],
      caseTypeIds: [],
      partnerIds: [],
      statusIds: [],
      workflowIds: [],
      organizationIds: [],
      policyTypeIds: [],
      vehicleIds: [],
      isInternal: this.isInternal(),
      page: this.state.page + 1,
      pageSize: this.state.pageSize,
      text: '',
      isNew: false,
      dateFilterCode: this.state.selectedOrgFilter === null ? FilterTypeCodeEnum.DS.toString() : this.state.selectedOrgFilter!.filterType!.code,
      dateFilterStartDate: this.state.filterStartDate
        ? new Date(this.state.filterStartDate.setHours(0, 0, 0, 0))
        : null,
      datefilterEndDate: this.state.filterEndDate
        ? new Date(this.state.filterEndDate.setHours(23, 59, 59, 999))
        : null,
      plateNumberSearchText: '',
      vinSearchText:  '',
      caseNumberSearchText: '',
      isAssignedToId: false,
      countryCode: null,
      makeSearchText: '',
      modelSearchText: ''
    }  as CaseFilters);

    const workFlows = this.state.userWorkflowsList.filter(
      (item) => filters.workflowIds.indexOf(item.id) !== -1
    );
    const workflowList = workFlows.map((item) => {
      const workflow = {} as IWorkflow;

      workflow.workflowId = item.id;
      workflow.workflowDisplayName = item.displayName;
      return workflow;
    });

    const [orgCasePartnerList, orgEntities, assignedToList, createdByList] = await Promise.all([
      this.organizationService.GetUserOrganizationsByIds(filters.partnerIds),
      this.organizationService.GetUserOrganizationsByIds(filters.organizationIds),
      this.appUserService.GetUsersInfo(filters.assignedToList),
      this.appUserService.GetUsersInfo(filters.createdByList)
    ]);

    this.setState({
      organizationEntities: orgEntities,
      workflowsList: workflowList,
      organizationCasePartnerList: orgCasePartnerList,
      caseStatusList: filters.caseStatuses,
      caseTypesList: filters.caseTypes,
      assignedToUserList: assignedToList,
      createdByUserList: createdByList,
      isLoading: false
    });
  };

  onClick = (id: number) => {
    this.props.history.push('/cases/' + id);
  };

  onOrderRowClick = async (e: any, id: number) => {
    const order = this.state.orderList.find((item) => item.id === id);
    order!.caseVehicle = this.state.selectedCase!.caseVehicle;
    this.setState({
      isOrderDialogOpen: true,
      selectedOrder: order!
    });
  };

  getOrderColumns = () => {
    const columns = [
      {
        name: 'id',
        label: 'ID',
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className=" text-primary"
                onClick={(e) => this.onOrderRowClick(e, tableMeta.rowData[0])}
              >
                <span>{value}</span>
              </Button>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'supplier.name',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_SUPPLIER_LABEL', 'Furnizor'),
        options: { filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'amount',
        label: this.translatorService.Tranlate('ORDERS_VALUE_LABEL', 'Valoare'),
        options: {
          filter: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            return value ?  value.toFixed(2) : '';
          }
        }
      },
      {
        name: 'currencyCode',
        label: this.translatorService.Tranlate('ORDERS_CURRENCY_LABEL', 'Moneda'),
        options: {
          filter: false
        }
      },
      {
        name: 'createdByUser.userName',
        label: this.translatorService.Tranlate('ORDERS_MADE_BY_LABEL', 'Efectuat De'),
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'orderStatus.displayName',
        label: this.translatorService.Tranlate('ORDERS_STATUS_LABEL', 'Stare'),
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: 'supplierOrderNumber',
        label: this.translatorService.Tranlate('ORDERS_ID_FURNIZOR_LABEL', 'ID Furnizor'),
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className=" text-primary"
                onClick={(e) => {
                  this.onSupplierOrderNumberRowClick(e, tableMeta.rowData[1]);
                }}
              >
                <span>{value}</span>
              </Button>
            );
          }
        } as MUIDataTableColumnOptions
      }
    ];

    return columns;
  };


  onSupplierOrderNumberRowClick = async (e: any, supplierName: string) => {
    if (supplierName == SupplierCode.AUGSBURG) {
      window.open('https://www.aicat.ro/csp/berta/portal/index.csp', '_blank');
    }
  };
  getColumns = () => {
    const columns = [
      {
        name: 'id',
        label: this.translatorService.Tranlate('CASES_CASE_ID', 'ID'),
        options: {
          sort: false,
          setCellProps: () => ({ style: { cursor: 'pointer' } }),
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return (
              <Grid container direction="column">
                <Grid
                  item
                  xs={12}
                  style={{
                    color: '#007bff',
                    cursor: 'pointer'
                  }}
                  onClick={() => this.onClick(value)}
                >
                  <div className="font-size-sm">{value}</div>
                </Grid>
                <Grid item xs={12}>
                  <div className="font-size-sm">{tableMeta.rowData[2]}</div>
                </Grid>
                <Grid item xs={12}>
                  <div className="font-size-sm">{tableMeta.rowData[3]}</div>
                </Grid>
              </Grid>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseEvent.caseNumber',
        label: this.translatorService.Tranlate('CASES_CASE_NUMBER', 'Numar Dosar'),
        options: {
          sort: false,
          setCellProps: () => ({ style: { cursor: 'pointer' } })
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseType.displayName',
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'organizationOwner.name',
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseWorkflow.workflowDisplayName',
        label: this.translatorService.Tranlate('CASES_WORKFLOW_HEADER', 'Date Flux'),
        options: {
          sort: false,
          setCellProps: () => ({ style: { cursor: 'pointer' } }),
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return (
              <Grid container direction="column">
                <Grid item xs={12}>
                  <div className="font-size-sm">{value}</div>
                </Grid>
                <Grid item xs={12}>
                  <div className="font-size-sm">{tableMeta.rowData[5]}</div>
                </Grid>
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {moment
                      .utc(tableMeta.rowData[6])
                      .local()
                      .format(this.props.appState.longDateFormat)}
                  </div>
                </Grid>
              </Grid>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'createdByUser.userName',
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateCreation',
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseVehicle.model',
        label: this.translatorService.Tranlate('CASES_VEHICLE_HEADER', 'Masina'),
        options: {
          sort: false,
          setCellProps: () => ({ style: { cursor: 'pointer' } }),
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const casee = this.state.caseList.find((item) => item.id === tableMeta.rowData[0]);
            if (R.isNil(casee)) {
              return;
            }

            return (
              <Grid container direction="column">
                <Grid item xs={12}>
                  <div className="font-size-sm">{VehicleHelper.GetBrand(casee, casee.vehicle)}</div>
                </Grid>
                <Grid item xs={12}>
                  <div className="font-size-sm">{VehicleHelper.GetModel(casee, casee.vehicle)}</div>
                </Grid>
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {VehicleHelper.GetPlateNumber(casee, casee.vehicle)}
                  </div>
                </Grid>
              </Grid>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseVehicle.modelId',
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseVehicle.plateNumber',
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseStatus.user.userName',
        label: this.translatorService.Tranlate('CASES_MODIFIED_BY_HEADER', 'Modificat De'),
        options: {
          sort: false,
          setCellProps: () => ({ style: { cursor: 'pointer' } }),
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return (
              <Grid container direction="column">
                <Grid item xs={12}>
                  <div className="font-size-sm">{value}</div>
                </Grid>
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {moment
                      .utc(tableMeta.rowData[11])
                      .local()
                      .format(this.props.appState.longDateFormat)}
                  </div>
                </Grid>
              </Grid>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseStatus.date',
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseStatus.assignedTo.userName',
        label: this.translatorService.Tranlate('CASES_ASSIGNED_TO_HEADER', 'Asignat La'),
        options: {
          sort: false,
          setCellProps: () => ({ style: { cursor: 'pointer' } }),
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return (
              <Grid container direction="column">
                <Grid item xs={12}>
                  <div className="font-size-sm">{value}</div>
                </Grid>
                {!this.state.showCaseStatus && (
                  <Grid item xs={12}>
                    <div className="font-size-sm">{tableMeta.rowData[13]}</div>
                  </Grid>
                )}
              </Grid>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseStatus.caseStatus.displayName',
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseStatus.caseStatus.code',
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseStatus.caseStatus.displayName',
        label:  this.translatorService.Tranlate('CASES_STATUS_HEADER', 'Status'),
        options: {
          display: this.state.showCaseStatus ? 'true' : 'false',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({
            align: 'center',
            style: { padding: '0px' }
          }),
          setCellProps: () => ({
            align: 'center',
            style: { padding: '0px' }
          }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return tableMeta.rowData[14] === CaseStatusTypeCode.DRAFT ? (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={async () => await this.onDelete(tableMeta.rowData[0])}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            ) : null;
          }
        }
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({
            align: 'center',
            style: { padding: '0px' }
          }),
          setCellProps: () => ({
            align: 'center',
            style: { padding: '0px' }
          }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return this.state.showCopyCase ? (
              <Tooltip title={this.translatorService.Tranlate('COPY_CASE', 'Copiaza dosar')}>
                <IconButton
                  aria-label="copy"
                  color="inherit"
                  className="text-primary"
                  size="small"
                  onClick={async () => {
                    const currentCase = _.find([...this.state.caseList], function (object) {
                      return object.id == tableMeta.rowData[0];
                    });
                    if (currentCase) {
                      await this.onCopyCase(currentCase);
                    }
                  }}
                >
                  <FileCopyOutlined />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            );
          }
        }
      }
    ];
    if (this.state.showOrderDetails) {
      columns.push({
        name: '',
        label:  this.translatorService.Tranlate('ORDERS_DIALOG_TITLE', 'Detalii comanda'),
        options: {
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <div className="text-center">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ width: '50px' }}
                  onClick={async () => {
                    const currentCase = _.find([...this.state.caseList], function (object) {
                      return object.id == tableMeta.rowData[0];
                    });
                    if (currentCase) {
                      await this.loadPieces(tableMeta.rowData[0], currentCase);
                    }
                  }}
                >
                 {this.translatorService.Tranlate('PIECES_LABEL', 'Piese')}
                </Button>
              </div>
            ) ;
          }
        }
      });
    }

    return columns;
  };

  onCopyCase = async (currentCase: Case) => {
    try {
      this.setState({ isLoading: true });
      const newCase = await this.caseService.CopyCase(
        currentCase.id,
        currentCase.caseWorkflow.workflowId,
        null,
        null,
        null
      );
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
      this.props.history.push('/cases/' + newCase!.id, 'Case' + newCase!.id);
    } catch (err: any) {
      this.setState({ isLoading: false });
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(err.response.data, 'Eroare copiere dosar!'),
        { variant: 'error' }
      );
    }
  };

  onDelete = async (id: number) => {
    try {
      await this.caseService.RemoveCase(id);

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });

      const caseList = [...this.state.caseList];

      const index = caseList.findIndex((item) => item.id === id);
      caseList.splice(index, 1);
      this.setState({ caseList: caseList });
    } catch (err) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  loadPieces = async (id: number, selectedCase: Case) => {
    try {
      this.setState({
        isLoadingDialog: true,
        isDialogOpen: true,
        selectedCase: selectedCase
      });
      this.props.caseSettingsState.caseSettings = {
        workflow: { 
          id: selectedCase.caseWorkflow.workflowId
        }
      } as CaseSettings;
      const orderList = await this.orderService.GetOrdersWithParts(id);
      const [users, clients, suppliersInit] = await Promise.all([
        this.appUserService.GetUsersInfo(
          Array.from(new Set(orderList.map((item) => item.createdBy)))
        ),
        this.organizationService.GetUserOrganizationsByIds(
          Array.from(new Set(orderList.map((item) => item.clientId)))
        ),
        this.organizationService.GetUserOrganizationsByIds(
          Array.from(new Set(orderList.map((item) => item.supplierId)))
        ),
      ]);

      orderList.forEach((p) => {
        const supplier = suppliersInit.find((item) => item.id === p.supplierId);
        const user = users.find((item) => item.id === p.createdBy);
        const client = clients.find((item) => item.id === p.clientId);
  
        p.createdByUser = user === undefined ? null : user;
        p.supplier = supplier === undefined ? null : supplier;
        p.client = client === undefined ? null : client;
      });

      console.log('caseSettings', this.props.caseSettingsState.caseSettings);
      console.log('orderList', orderList);

      this.setState({ orderList: orderList, isDialogOpen: true, isLoadingDialog: false });
    } catch (err) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  closeDialog = async () => {
    this.setState({
      isDialogOpen: false,
      orderList: []
    });
  };

  closeOrderDialog = async () => {
    this.setState({
      isOrderDialogOpen: false,
      // workflowSuppliers: suppliers,
      //isLoading: true
    });

    // await this.loadData();
  };

  
  public orderChange = (order: Order) => {
    this.setState({
      orderList: this.state.orderList.map((el: Order) =>
        el.id !== order.id
          ? el
          : {
              ...el,
              amount: order.amount,
              amountTVA: order.amountTVA,
              amountWithoutTVA: order.amountWithoutTVA,
              orderParts: [...order.orderParts!],
              orderStatus: order.orderStatus,
              orderStatusId: order.orderStatusId
            }
      )
    });
  };

  onTabChange = (event: any, newValue: number) => {
    this.setState(
      {
        selectedTab: newValue,
        page: 0
      },
      async () => await this.loadCases(0, this.state.pageSize)
    );
  };
  handleOrgEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedOrgEntities: newValue
      },
      async () => await this.loadCases(this.state.page, this.state.pageSize)
    );
  };

  handleWorkflowsChange = (newValue: any[]) => {
    this.setState(
      {
        selectedWorkflows: newValue
      },
      async () => await this.loadCases(this.state.page, this.state.pageSize)
    );
  };

  handleOrgCasePartnersChange = (newValue: any[]) => {
    this.setState(
      {
        selectedOrgCasePartner: newValue
      },
      async () => await this.loadCases(this.state.page, this.state.pageSize)
    );
  };
  handleOrFilterChange = (newValue: OrganizationFilterType | null) => {
    this.setState(
      {
        selectedOrgFilter: newValue
      },
      async () => await this.loadCases(this.state.page, this.state.pageSize)
    );
  };
  handleCaseStatusChange = (newValue: any[]) => {
    this.setState(
      {
        selectedCaseStatus: newValue
      },
      async () => await this.loadCases(this.state.page, this.state.pageSize)
    );
  };

  handlePolicyTypeChange = (newValue: any[]) => {
    this.setState(
      {
        selectedPolicyType: newValue
      },
      async () => await this.loadCases(this.state.page, this.state.pageSize)
    );
  };

  handleCaseTypeChange = (newValue: any[]) => {
    this.setState(
      {
        selectedCaseType: newValue
      },
      async () => await this.loadCases(this.state.page, this.state.pageSize)
    );
  };
  handleAssignedToChange = (newValue: any[]) => {
    this.setState(
      {
        selectedAssignedToUser: newValue
      },
      async () => await this.loadCases(this.state.page, this.state.pageSize)
    );
  };

  handleCreatedByChange = (newValue: any[]) => {
    this.setState(
      {
        selectedCreatedByUser: newValue
      },
      async () => await this.loadCases(this.state.page, this.state.pageSize)
    );
  };


  handleVinChange = (newValue: string) => {
    this.setState({
      vinSearchText: newValue
    });
  };

  handlePlateNumberChange = (newValue: string) => {
    this.setState({
      plateNumberSearchText: newValue
    });
  };

  handleCaseNumberChange = (newValue: string) => {
    this.setState({
      caseNumberSearchText: newValue
    });
  };

  handleMakeChange = (newValue: string) => {
    this.setState({
      makeSearchText: newValue
    });
  };
  
  handleModelChange = (newValue: string) => {
    this.setState({
      modelSearchText: newValue
    });
  };

  handlefilterStartDateChange = async (date: MaterialUiPickersDate | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState(
      {
        filterStartDate: date === null || !date.isValid ? null : date!.toDate()
      },
      async () => await this.reloadCases(this.state.page, this.state.pageSize)
    );
  };

  handlefilterEndDateChange = async (date: MaterialUiPickersDate | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState(
      {
        filterEndDate: date === null || !date.isValid ? null : date!.toDate()
      },
      async () => await this.reloadCases(this.state.page, this.state.pageSize)
    );
  };

  reloadCases = async (page: number, rowsPerPage: number) => {
    this.props.enqueueSnackbar(
      this.translatorService.Tranlate('CASE_FILTERS_LOADING', 'Filtrarea este in curs!'), 
      {
        variant: 'info',
        autoHideDuration: 5000
      }
    );
  
    this.setState({ isLoadingCases: true });
  
    const caseList = await this.caseService.GetCases({
      assignedToList: this.state.selectedAssignedToUser.map((item) => item.id),
      createdByList: this.state.selectedCreatedByUser.map((item) => item.id),
      caseTypeIds: this.state.selectedCaseType.map((item) => item.id),
      partnerIds: this.state.selectedOrgCasePartner.map((item) => item.id),
      statusIds: this.state.selectedCaseStatus.map((item) => item.id),
      workflowIds: this.state.selectedWorkflows.map((item) => item.workflowId),
      organizationIds: this.state.selectedOrgEntities.map((item) => item.id),
      policyTypeIds: this.state.selectedPolicyType.map((item) => item.id),
      isInternal: this.isInternal(),
      page: page + 1,
      pageSize: rowsPerPage,
      text: '',
      isNew: false,
      dateFilterCode: this.state.selectedOrgFilter === null 
        ? FilterTypeCodeEnum.DS.toString() 
        : this.state.selectedOrgFilter!.filterType!.code,
      dateFilterStartDate: this.state.filterStartDate 
        ? new Date(this.state.filterStartDate.setHours(0, 0, 0, 0)) 
        : null,
      datefilterEndDate: this.state.filterEndDate 
        ? new Date(this.state.filterEndDate.setHours(23, 59, 59, 999)) 
        : null,
      plateNumberSearchText: this.state.plateNumberSearchText.replace(/\s+/g, ''),
      vinSearchText: this.state.vinSearchText,
      caseNumberSearchText: this.state.caseNumberSearchText
    } as CaseFilters);
  
    const [users, orgOwners, vehicles] = await Promise.all([
      this.appUserService.GetUsersInfo(
        Array.from(new Set(caseList.cases.map((item) => item.caseStatus.userId)))
      ),
      this.organizationService.GetUserOrganizationsByIds(
        Array.from(new Set(caseList.cases.map((item) => item.organizationOwnerId)))
      ),
      this.vehicleService.GetVehicleList(
        Array.from(
          new Set(
            caseList.cases.filter((c) => !R.isNil(c.vehicleId)).map((item) => item.vehicleId!)
          )
        )
      )
    ]);
  
    this.materializeCaseObject(
      caseList.cases,
      this.state.userWorkflowsList,
      vehicles,
      users,
      orgOwners
    );
  
    this.setState({
      caseList: caseList.cases,
      page: caseList.page - 1,
      pageSize: rowsPerPage,
      count: caseList.total
    },
    async () => {
      await this.reloadFilters();
      this.setState({ isLoadingCases: false });
    });
  };  

  renderFilters = () => {
    return (
      <div className="my-3">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              id="orgEntities"
              size="small"
              className="m-2"
              multiple
              options={this.state.organizationEntities.sort(function (a, b) {
                return a.displayName.localeCompare(b.displayName);
              })}
              value={this.state.selectedOrgEntities}
              onChange={(e: any, newValue: any | null) => this.handleOrgEntitiesChange(newValue)}
              getOptionLabel={(option: Organization) => option.displayName || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="orgEntities"
                  value={this.state.selectedOrgEntities}
                  label={this.translatorService.Tranlate(
                    'CASES_ORGANIZATION_LABEL',
                    'Organizatia mea'
                  )}
                  fullWidth
                />
              )}
            />
            {!this.state.hideLkqColumns && (
              <Autocomplete
                id="workflows"
                className="m-2"
                size="small"
                multiple
                options={this.state.workflowsList.sort(function (a, b) {
                  return a.workflowDisplayName.localeCompare(b.workflowDisplayName);
                })}
                value={this.state.selectedWorkflows}
                onChange={(e: any, newValue: any | null) => this.handleWorkflowsChange(newValue)}
                getOptionLabel={(option: IWorkflow) => option.workflowDisplayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="workflows"
                    value={this.state.selectedWorkflows}
                    label={this.translatorService.Tranlate('CASES_WORKFLOW_LABEL', 'Flux de lucru')}
                    fullWidth
                  />
                )}
              />
            )}
            {!this.state.hideLkqColumns && (
              <Autocomplete
                id="orgCasePartner"
                size="small"
                className="m-2"
                multiple
                options={this.state.organizationCasePartnerList.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedOrgCasePartner}
                onChange={(e: any, newValue: any | null) =>
                  this.handleOrgCasePartnersChange(newValue)
                }
                getOptionLabel={(option: Organization) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="orgCasePartner"
                    value={this.state.selectedOrgCasePartner}
                    label={this.translatorService.Tranlate(
                      'CASES_CASE_PARTNER_LABEL',
                      'Partener dosar'
                    )}
                    fullWidth
                  />
                )}
              />
            )}

            <Autocomplete
              style={{ marginTop: this.state.hideLkqColumns ? '0px' : '11px' }}
              id="orgFilters"
              size="small"
              className="ml-2 mr-2 mb-2"
              options={this.props.appState.appUser!.organization!.filters!}
              value={this.state.selectedOrgFilter}
              onChange={(e: any, newValue: any | null) => this.handleOrFilterChange(newValue)}
              getOptionLabel={(option: OrganizationFilterType) =>
                option.filterType!.displayName || ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="orgFilter"
                  value={this.state.selectedOrgFilter}
                  label={this.translatorService.Tranlate(
                    'CASES_CASE_FILTER_TYPE_LABEL',
                    'Tip Data'
                  )}
                  fullWidth
                />
              )}
            />
            
            <Autocomplete
              id="userCreatedBy"
              className="m-2"
              size="small"
              multiple
              options={this.state.createdByUserList.sort(function (a, b) {
                return a.userName.localeCompare(b.userName);
              })}
              value={this.state.selectedCreatedByUser}
              onChange={(e: any, newValue: any | null) => this.handleCreatedByChange(newValue)}
              getOptionLabel={(option: AppUser) => option.userName || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="userCreatedBy"
                  value={this.state.selectedAssignedToUser}
                  label={this.translatorService.Tranlate('USER_ROL_CREATEDBY_HEADER', 'Creat de')}
                  fullWidth
                />
              )}
            />

            <div className="m-2">
              <TextField
                fullWidth
                id="vin"
                label={this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_VIN',
                  'Vin'
                )}
               value={this.state.vinSearchText}
               onChange={(e: any) => this.handleVinChange(e.target.value)}
               onBlur={(e: any) => this.loadCases(this.state.page, this.state.pageSize)}
              />
            </div>

            <div className="m-2">
              <TextField
                fullWidth
                id="caseNumber"
                label={this.translatorService.Tranlate('CASES_CASE_NUMBER', 'Numar Dosar')}
               value={this.state.caseNumberSearchText}
               onChange={(e: any) => this.handleCaseNumberChange(e.target.value)}
               onBlur={(e: any) => this.loadCases(this.state.page, this.state.pageSize)}
              />
            </div>
            {this.state.showMakeModelSearch && (
              <div className="m-2">
                <TextField
                  fullWidth
                  id="make"
                  label={this.translatorService.Tranlate(
                    'SEARCH_CASE_VEHICLE_MAKE',
                    'Marca'
                  )}
                  value={this.state.makeSearchText}
                  onChange={(e: any) => this.handleMakeChange(e.target.value)}
                  onBlur={(e: any) => this.loadCases(this.state.page, this.state.pageSize)}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="caseStatus"
              className="m-2"
              size="small"
              multiple
              options={this.state.caseStatusList.sort(function (a, b) {
                return a.displayName.localeCompare(b.displayName);
              })}
              value={this.state.selectedCaseStatus}
              onChange={(e: any, newValue: any | null) => this.handleCaseStatusChange(newValue)}
              getOptionLabel={(option: IReferential) => option.displayName || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="caseStatus"
                  value={this.state.selectedCaseStatus}
                  label={this.translatorService.Tranlate('CASES_CASE_STATUS_LABEL', 'Status dosar')}
                  fullWidth
                />
              )}
            />

            <Autocomplete
              id="caseType"
              size="small"
              className="m-2"
              multiple
              options={this.state.caseTypesList.sort(function (a, b) {
                return a.displayName.localeCompare(b.displayName);
              })}
              value={this.state.selectedCaseType}
              onChange={(e: any, newValue: any | null) => this.handleCaseTypeChange(newValue)}
              getOptionLabel={(option: IReferential) => option.displayName || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="caseType"
                  value={this.state.selectedCaseType}
                  label={this.translatorService.Tranlate('CASES_CASE_TYPE_LABEL', 'Tip dosar')}
                  fullWidth
                />
              )}
            />

            <Autocomplete
              id="userAssignedTo"
              className="m-2"
              size="small"
              multiple
              options={this.state.assignedToUserList.sort(function (a, b) {
                return a.userName.localeCompare(b.userName);
              })}
              value={this.state.selectedAssignedToUser}
              onChange={(e: any, newValue: any | null) => this.handleAssignedToChange(newValue)}
              getOptionLabel={(option: AppUser) => option.userName || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="userAssignedTo"
                  value={this.state.selectedAssignedToUser}
                  label={this.translatorService.Tranlate('CASES_ASSIGNED_TO_LABEL', 'Asignat la')}
                  fullWidth
                />
              )}
            />
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={LocalizedUtils}
              locale={this.props.appState.language}
            >
              <DatePicker
                style={{ width: '50%' }}
                className="m-0 pl-2 pr-2 pb-0"
                InputLabelProps={{
                  style: { paddingLeft: '10px' }
                }}
                disableToolbar
                variant="inline"
                format={this.props.appState.dateFormat.toUpperCase()}
                margin="normal"
                label={this.translatorService.Tranlate('CASES_FILTER_START_DATE', 'Data inceput')}
                value={this.state.filterStartDate}
                onChange={this.handlefilterStartDateChange}
                autoOk={true}
                // minDate={activeButton === 1 ? new Date() : this.state.minDate}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={LocalizedUtils}
              locale={this.props.appState.language}
            >
              <DatePicker
                style={{ width: '50%' }}
                className="m-0 pl-2 pr-2 pb-0"
                InputLabelProps={{
                  style: { paddingLeft: '10px' }
                }}
                disableToolbar
                variant="inline"
                format={this.props.appState.dateFormat.toUpperCase()}
                margin="normal"
                label={this.translatorService.Tranlate('CASES_FILTER_END_DATE', 'Data sfarsit')}
                value={this.state.filterEndDate}
                onChange={this.handlefilterEndDateChange}
                autoOk={true}
              />
            </MuiPickersUtilsProvider>
            {!this.state.hideLkqColumns && (
              <Autocomplete
                id="policyType"
                className="m-2"
                size="small"
                multiple
                options={this.state.casePolicyTypesList.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedPolicyType}
                onChange={(e: any, newValue: any | null) => this.handlePolicyTypeChange(newValue)}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="policyType"
                    value={this.state.selectedPolicyType}
                    label={this.translatorService.Tranlate(
                      'FRM_EVENT_DATA_POLICY_TYPE',
                      'Tip Polita'
                    )}
                    fullWidth
                  />
                )}
              />
            )}
            <div className="m-2">
              <TextField
                fullWidth
                id="plateNumber"
                label={this.translatorService.Tranlate(
                  'ORDER_VEHICLES_VEHICLE_PLATE_NUMBER',
                  'Nr. inmatriculare'
                )}
                value={this.state.plateNumberSearchText}
                onChange={(e: any) => this.handlePlateNumberChange(e.target.value)}
                onBlur={(e: any) => this.loadCases(this.state.page, this.state.pageSize)}
              />
            </div>
            {this.state.showMakeModelSearch && (
              <div className="m-2">
                <TextField
                  fullWidth
                  id="model"
                  label={this.translatorService.Tranlate(
                    'SEARCH_CASE_VEHICLE_MODEL',
                    'Model'
                  )}
                  value={this.state.modelSearchText}
                  onChange={(e: any) => this.handleModelChange(e.target.value)}
                  onBlur={(e: any) => this.loadCases(this.state.page, this.state.pageSize)}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  onCellClick = (
    colData: any,
    cellMeta: { colIndex: number; rowIndex: number; dataIndex: number }
  ) => {
    if (cellMeta.colIndex === 15 || cellMeta.colIndex == 16 || cellMeta.colIndex == 17  || cellMeta.colIndex == 18) {
      return;
    }

    this.onClick(this.state.caseList[cellMeta.dataIndex].id);
  };

  public render() {
    this.workflowService = (this.context as AppContext).workflowService;
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.orderService = (this.context as AppContext).orderService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 0,
      serverSide: true,
      count: this.state.count,
      rowsPerPage: this.state.pageSize,
      page: this.state.page,
      onCellClick: this.onCellClick,
      onTableChange: async (action: any, tableState: any) => {
        switch (action) {
          case 'changePage':
            await this.loadCases(tableState.page, tableState.rowsPerPage);
            break;
          case 'changeRowsPerPage':
            await this.loadCases(tableState.page, tableState.rowsPerPage);
            break;
          case 'sort':
            // this.sort(tableState.page, tableState.sortOrder);
            break;
          default:
          //console.log('action not handled.');
        }
      }
    };

    const orderOptions = {
      filter: false,
      sort: false,
      search: false,
      pagination: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      elevation: 0,
      textLabels: MUITranslations.GetTranslations(this.translatorService)
    };

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <Card>
          {this.state.hidePartnerTab ? ( 
            <>
              <AppBar position="static" elevation={0} color="secondary">
                <Tabs
                  TabIndicatorProps={{ style: { height: '4px' } }}
                  value={this.state.selectedTab}
                  onChange={this.onTabChange}
                  variant="fullWidth"
                  centered={true}
                  indicatorColor="primary"
                  aria-label="simple tabs example"
                >
                  <Tab label={this.translatorService.Tranlate('CASES_ALL_TAB', 'Toate')} />
                </Tabs>
              </AppBar>

              <div>
                <TabPanel value={this.state.selectedTab} index={0}>
                  <div className="m-3">
                    {this.renderFilters()}
                    <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                      <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
                    </div>
                    {this.state.isLoadingCases ? (
                      <Loader />
                    ) : (
                      <MUIDataTable
                        title=""
                        data={this.state.caseList}
                        columns={this.getColumns()}
                        options={options}
                      />
                    )}
                  </div>
                </TabPanel>
                {this.state.isDialogOpen && (
                  <Dialog
                    onClose={this.closeDialog}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.isDialogOpen}
                    fullScreen={false}
                    disableBackdropClick
                    fullWidth={true}
                    maxWidth="md"
                  >
                    <DialogTitle id="customized-dialog-title">
                      <Grid container>
                        <Grid item xs={11}>
                          <Typography className="MuiTypography-h4">
                            {this.translatorService.Tranlate('ORDERS_LIST_TITLE', 'Lista comenzi')}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton aria-label="close" onClick={this.closeDialog} size="small">
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                      <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                        <ScaleLoader color={'var(--primary)'} loading={this.state.isLoadingDialog} />
                      </div>
                      {!this.state.isLoadingDialog && (
                        <MUIDataTable
                          title=""
                          data={this.state.orderList}
                          columns={this.getOrderColumns()}
                          options={orderOptions}
                        />
                      )}
                    </DialogContent>
                  </Dialog>
                )}

                {this.state.isOrderDialogOpen && (
                  <Dialog
                    onClose={this.closeOrderDialog}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.isOrderDialogOpen}
                    fullScreen={true}
                    fullWidth={true}
                  >
                    <DialogTitle
                      id="customized-dialog-title"
                      style={{ color: '#ffffff', backgroundColor: '#1F2857', textAlign: 'center' }}
                    >
                      <span style={{ fontSize: '20px', lineHeight: '24px' }}>
                        {this.translatorService.Tranlate('ORDERS_DIALOG_TITLE', 'Detalii comanda')}
                      </span>
                      <IconButton
                        aria-label="close"
                        className="position-absolute"
                        style={{ right: 12, top: 12, color: '#ffffff' }}
                        onClick={this.closeOrderDialog}
                        size="small"
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
                      {this.state.selectedOrder !== null && (
                        <OrderDetails
                          {...this.props}
                          onOrderChange={this.orderChange}
                          orderDetails={this.state.selectedOrder}
                          hasRights={true}
                          additionTariff={{ id: 0, value: 0 } as TariffPercentResponse}
                          discountTariff={{ id: 0, value: 0 } as TariffPercentResponse}
                          automatePlaceOrder={false}
                          workflowAutomatePlaceOrder={false}
                        />
                      )}
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </>
          ) : (
            <>
              <AppBar position="static" elevation={0} color="secondary">
                <Tabs
                  TabIndicatorProps={{ style: { height: '4px' } }}
                  value={this.state.selectedTab}
                  onChange={this.onTabChange}
                  variant="fullWidth"
                  centered={true}
                  indicatorColor="primary"
                  aria-label="simple tabs example"
                >
                  <Tab label={this.translatorService.Tranlate('CASES_ALL_TAB', 'Toate')} />
                  <Tab label={this.translatorService.Tranlate('CASES_PARTENERS_TAB', 'Parteneri')} />
                  <Tab label={this.translatorService.Tranlate('CASES_INTERNAL_TAB', 'Intern')} />
                </Tabs>
              </AppBar>

              <div>
                <TabPanel value={this.state.selectedTab} index={0}>
                  <div className="m-3">
                    {this.renderFilters()}
                    <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                      <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
                    </div>
                    {this.state.isLoadingCases ? (
                      <Loader />
                    ) : (
                      <MUIDataTable
                        title=""
                        data={this.state.caseList}
                        columns={this.getColumns()}
                        options={options}
                      />
                    )}
                  </div>
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={1}>
                  <div className="m-3">
                    {this.renderFilters()}
                    <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                      <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
                    </div>
                    {this.state.isLoadingCases ? (
                      <Loader />
                    ) : (
                      <MUIDataTable
                        title=""
                        data={this.state.caseList}
                        columns={this.getColumns()}
                        options={options}
                      />
                    )}
                  </div>
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={2}>
                  <div className="m-3">
                    {this.renderFilters()}
                    <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                      <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
                    </div>
                    {this.state.isLoadingCases ? (
                      <Loader />
                    ) : (
                      <MUIDataTable
                        title=""
                        data={this.state.caseList}
                        columns={this.getColumns()}
                        options={options}
                      />
                    )}
                  </div>
                </TabPanel>

                {this.state.isDialogOpen && (
                  <Dialog
                    onClose={this.closeDialog}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.isDialogOpen}
                    fullScreen={false}
                    disableBackdropClick
                    fullWidth={true}
                    maxWidth="md"
                  >
                    <DialogTitle id="customized-dialog-title">
                      <Grid container>
                        <Grid item xs={11}>
                          <Typography className="MuiTypography-h4">
                            {this.translatorService.Tranlate('ORDERS_LIST_TITLE', 'Lista comenzi')}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton aria-label="close" onClick={this.closeDialog} size="small">
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                      <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                        <ScaleLoader color={'var(--primary)'} loading={this.state.isLoadingDialog} />
                      </div>
                      {!this.state.isLoadingDialog && (
                        <MUIDataTable
                          title=""
                          data={this.state.orderList}
                          columns={this.getOrderColumns()}
                          options={orderOptions}
                        />
                      )}
                    </DialogContent>
                  </Dialog>
                )}

                {this.state.isOrderDialogOpen && (
                  <Dialog
                    onClose={this.closeOrderDialog}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.isOrderDialogOpen}
                    fullScreen={true}
                    fullWidth={true}
                  >
                    <DialogTitle
                      id="customized-dialog-title"
                      style={{ color: '#ffffff', backgroundColor: '#1F2857', textAlign: 'center' }}
                    >
                      <span style={{ fontSize: '20px', lineHeight: '24px' }}>
                        {this.translatorService.Tranlate('ORDERS_DIALOG_TITLE', 'Detalii comanda')}
                      </span>
                      <IconButton
                        aria-label="close"
                        className="position-absolute"
                        style={{ right: 12, top: 12, color: '#ffffff' }}
                        onClick={this.closeOrderDialog}
                        size="small"
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
                      {this.state.selectedOrder !== null && (
                        <OrderDetails
                          {...this.props}
                          onOrderChange={this.orderChange}
                          orderDetails={this.state.selectedOrder}
                          hasRights={true}
                          additionTariff={{ id: 0, value: 0 } as TariffPercentResponse}
                          discountTariff={{ id: 0, value: 0 } as TariffPercentResponse}
                          automatePlaceOrder={false}
                          workflowAutomatePlaceOrder={false}
                        />
                      )}
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            </>
          )}
        
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    caseSettingsState: state.caseSettings
  }),
  null
)(withSnackbar(Cases as any));
