import * as React from 'react';

import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../store';
import { ScaleLoader } from 'react-spinners';
import { Box, Grid, ListItem } from '@material-ui/core';
import { withRouter } from 'react-router';
import moment from 'moment';
import * as R from 'ramda';

import { Order, OrderFilters } from '../../interfaces/Order';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import OrderDetails from './OrderDetails';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import { TariffPercentResponse } from '../../interfaces/Case';

interface Vehicle {
  id: number;
  plateNumber: string;
  name: string;
  vin: string;
  status?: string;
  firstRegistrationDate: string;
  orders: Order[];
}

export interface ExternalSearchOrderProps {
  handleSearchPopverClose: () => void;
  searchValue: string;
  searchId: string;
}
type SearchOrderProps = { appState: AppState } & ExternalSearchOrderProps & RouteComponentProps;

interface ISearchOrderState {
  vehicleList: Vehicle[];
  isLoading: boolean;
  orderList: Order[];
  isDialogOpen: boolean;
  selectedOrder: Order | null;
}

class SearchOrder extends React.PureComponent<SearchOrderProps, ISearchOrderState> {
  private cbContext!: AppContext;
  static contextType = ApplicationContext;

  state = {
    vehicleList: [],
    isLoading: false,
    orderList: [],
    isDialogOpen: false,
    selectedOrder: null
  } as ISearchOrderState;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public async componentDidMount() {}

  async componentDidUpdate(prevProps: SearchOrderProps) {
    if (this.props.searchId !== prevProps.searchId) {
      this.setState(
        {
          isLoading: true
        },
        this.loadOrders
      );
    }
  }

  loadOrders = async () => {
    const vehicles = await this.loadData();

    this.setState({
      isLoading: false,
      vehicleList: vehicles
    });
  };

  loadData = async (): Promise<Vehicle[]> => {
    const [orders] = await Promise.all([
      this.cbContext.orderService.GetOrders({
        text: this.props.searchValue.replace(/\s+/g, '')
      } as any as OrderFilters)
    ]);
    const orderList = orders.orders;
    this.setState({
      orderList: orderList
    });
    const orgIds = _.uniq(orderList.map(({ clientId }) => clientId));

    const assignedToList = _.uniq(orderList.map(({ createdBy }) => createdBy));
    const uniqueAssignedToList = assignedToList.filter((a, b) => assignedToList.indexOf(a) === b);

    const [users, orgEntities] = await Promise.all([
      this.cbContext.appUserService.GetUsersInfo(uniqueAssignedToList),
      orgIds.length == 0 ? [] : this.cbContext.organizationService.GetUserOrganizationsByIds(orgIds)
    ]);

    orders.orders.forEach((od) => {
      const client = orgEntities.find((item) => item.id === od.clientId);
      const user = users.find((item) => item.id === od.createdBy);

      od.createdByUser = R.isNil(user) ? null : user;
      od.client = R.isNil(client) ? null : client;
    });

    const orderVehicles = orders.orders.filter((i) => i.caseVehicle).map((i) => i.caseVehicle);
    const vehicles = orderVehicles.map((item) => {
      return {
        id: -1,
        vin: item.vin || '',
        plateNumber: item.plateNumber || '',
        name: item.model ? `${item.model?.brand} ${item.model?.name}` : '',
        numberOfKilometers: item.numberOfKilometers,
        status: item.currentStatus?.status?.displayName,
        firstRegistrationDate: item.registrationDate ? item.registrationDate : '',
        orders: orders.orders.filter((i) => i.caseVehicle?.id === item.id)
      };
    });

    return [...vehicles];
  };

  onOrderClick = async (id: number) => {
    const orderParts = await this.cbContext.orderService.GetOrderParts(id);
    const order = this.state.orderList.find((item) => item.id === id);
    order!.orderParts = orderParts;

    this.setState({
      isDialogOpen: true,
      selectedOrder: order!
    });
  };

  onVehicleClick = (id: number) => {
    if (!id || id < 0) {
      return;
    }

    if (!R.isNil(this.props.handleSearchPopverClose)) {
      this.props.handleSearchPopverClose();
    }

    const currentPath = this.props.history.location.pathname.toLowerCase();
    this.props.history.push('/vehicle/' + id);

    if (currentPath.indexOf('/vehicle/') !== -1) {
      this.props.history.go(0);
    }
  };

  closeDialog = async () => {
    this.setState({
      isDialogOpen: false,
      isLoading: true
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOrderChange = (order: Order | null) => {};

  renderVehicles = (vehicles: Vehicle[]) => {
    return vehicles.map((item, index) => (
      <div key={index}>
        <ListItem
          button
          style={{ backgroundColor: '#5383ff' }}
          divider
          key={index}
          onClick={(e) => this.onVehicleClick(item.id)}
        >
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <div className="font-size-sm text-white">{item.plateNumber}</div>
            </Grid>
            <Grid item xs={3}>
              <div>{item.status}</div>
              <div className="font-size-sm text-white">{item.vin}</div>
            </Grid>
            <Grid item xs={4}>
              <div className="font-size-sm text-white">{item.name}</div>
            </Grid>
            <Grid item xs={3}>
              <div> </div>
              <div className="font-size-sm text-white">
                {item.firstRegistrationDate
                  ? moment
                      .utc(item.firstRegistrationDate)
                      .local()
                      .format(this.props.appState.dateFormat)
                  : ' - '}
              </div>
            </Grid>
          </Grid>
        </ListItem>
        {this.renderOrders(item.orders)}
      </div>
    ));
  };

  renderOrders = (orders: Order[]) => {
    return orders.map((item, index) => (
      <ListItem button divider key={index} onClick={(e) => this.onOrderClick(item.id)}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <div>{item.id}</div>
          </Grid>
          <Grid item xs={4}>
            <div>{item.client!.displayName}</div>
            <div className="font-size-sm text-black-50">{item.createdByUser!.userName}</div>
          </Grid>
          <Grid item xs={3}>
            <div>{item.amount}</div>
            <div className="font-size-sm text-black-50">{item.currencyCode}</div>
          </Grid>

          <Grid item xs={3}>
            <div>{item!.orderStatus!.displayName}</div>
            <div className="font-size-sm text-black-50">
              {moment
                .utc(item.date)
                .local()
                .format(this.props.appState.dateFormat)}
            </div>
          </Grid>
        </Grid>
      </ListItem>
    ));
  };

  public render() {
    this.cbContext = this.context as AppContext;

    return (
      <React.Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>

        {!this.state.isLoading && !this.state.isDialogOpen && this.state.vehicleList.length != 0 ? (
          <Box maxHeight={300} overflow="auto">
            {this.renderVehicles(this.state.vehicleList)}
          </Box>
        ) : null}

        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullScreen={true}
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{
              color: '#ffffff',
              backgroundColor: '#1F2857',
              textAlign: 'center'
            }}
          >
            <span style={{ fontSize: '20px', lineHeight: '24px' }}>
              {this.cbContext.translatorService.Tranlate('ORDERS_DIALOG_TITLE', 'Detalii comanda')}
            </span>
            <IconButton
              aria-label="close"
              className="position-absolute"
              style={{ right: 12, top: 12, color: '#ffffff' }}
              onClick={this.closeDialog}
              size={'small'}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
            {this.state.selectedOrder === null ? null : (
              <OrderDetails
                {...this.props}
                onOrderChange={this.onOrderChange}
                orderDetails={this.state.selectedOrder}
                hasRights={true}
                additionTariff={{
                  id: 0, 
                  value: 0 
                } as TariffPercentResponse} 
                discountTariff={{
                  id: 0, 
                  value: 0 
                } as TariffPercentResponse}  
                automatePlaceOrder={false} 
                workflowAutomatePlaceOrder={false}         
              />
            )}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withRouter(SearchOrder));
